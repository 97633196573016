import dayjs from 'dayjs';
import React from 'react';
import Icon from 'ui/atoms/Icon/Icon';
import Label from 'ui/atoms/Label/Label';
import Typography from 'ui/atoms/Typography/Typography';
import ComponentWrapper from 'ui/templates/ComponentWrapper/ComponentWrapper';

export interface Props {
  activeSinceDate: dayjs.Dayjs;
}

const MyProfileStatusConnected: React.FC<Props> = ({ activeSinceDate }) => (
  <ComponentWrapper flexDirection="column" gap="1rem">
    <ComponentWrapper flexDirection="column" gap="0.625rem">
      <Label>Status</Label>
      <Icon name="StatusConnected" color="success" id="status" width={75} height={12} />
    </ComponentWrapper>
    <ComponentWrapper flexDirection="column" gap="0.625rem">
      <Label>Active since</Label>
      <Typography variant="body1" id="drawer-my-profile-date" color="dTextHigh">
        {activeSinceDate.format('DD/MM/YYYY - HH:mm')}
      </Typography>
    </ComponentWrapper>
  </ComponentWrapper>
);

export default MyProfileStatusConnected;
