/* eslint-disable import/prefer-default-export */
/* eslint-disable no-magic-numbers */
import { MobileOpCode } from 'graphql/generated/globalTypes';
import { OptionProps } from 'ui/molecules/Select/Select';

export const actionSelectOptions: Array<OptionProps<null | MobileOpCode>> = [
  {
    value: MobileOpCode.ACCESS_GRANTED,
    label: 'Access granted'
  },
  {
    value: MobileOpCode.ACCESS_DENIED,
    label: 'Access denied'
  }
];
