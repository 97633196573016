import React from 'react';
import styled from 'styled-components';
import Icon from 'ui/atoms/Icon/Icon';
import Typography from 'ui/atoms/Typography/Typography';
import useBatteryStatusUI, { BatteryStatus } from 'hooks/useBatteryStatusUI/useBatteryStatusUI';

export interface CssProps {
  flex?: string;
}
export interface Props extends CssProps {
  status: BatteryStatus;
  id?: string;
}

const TableCellWrapper = styled.div<Partial<CssProps>>`
  display: flex;
  flex-direction: row;
  min-width: 0;
  gap: 0.5rem;
  align-items: center;
  ${({ flex }) => flex && `flex: ${flex}`};
`;

interface IconWrapperProps {
  opacity: null | string;
}

const IconWrapper = styled.div<IconWrapperProps>`
  align-items: center;
  justify-content: center;
  opacity: ${({ opacity }) => opacity};
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
`;

const BatteryStatusCell: React.FC<Props> = ({ status, flex, id }) => {
  const { getBatteryData } = useBatteryStatusUI();

  return (
    <TableCellWrapper flex={flex} id={id}>
      <IconWrapper opacity={getBatteryData(status).iconOpacity}>
        <Icon name={status} color={getBatteryData(status).iconColor} />
      </IconWrapper>
      <Typography variant="body4" color="lTextMedium">
        {getBatteryData(status).CellText}
      </Typography>
    </TableCellWrapper>
  );
};

export default BatteryStatusCell;
