/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum LocationType {
  EXTERNAL = "EXTERNAL",
  MANUAL = "MANUAL",
}

export enum MobileOpCode {
  ACCESS_DENIED = "ACCESS_DENIED",
  ACCESS_GRANTED = "ACCESS_GRANTED",
  BLE_CONNECTION_REFUSED = "BLE_CONNECTION_REFUSED",
  NONCE_NOT_CORRECT = "NONCE_NOT_CORRECT",
  NO_RESPONSE_FROM_LOCK = "NO_RESPONSE_FROM_LOCK",
  OPEN_COMMAND_SEND = "OPEN_COMMAND_SEND",
  TOKEN_SEND_TO_LOCK = "TOKEN_SEND_TO_LOCK",
}

export enum Role {
  EXTERNAL_GROUP_USER = "EXTERNAL_GROUP_USER",
  EXTERNAL_GROUP_USER_MANAGER = "EXTERNAL_GROUP_USER_MANAGER",
  NONE = "NONE",
}

export enum SortDirection {
  ASC = "ASC",
  DESC = "DESC",
}

export enum VisitSource {
  internal = "internal",
  outlook = "outlook",
}

export enum VisitsSortFields {
  DATE_START = "DATE_START",
}

export interface AccessEventsDateRange {
  rangeStart: GraphQL_AWSDateTime;
  rangeEnd: GraphQL_AWSDateTime;
}

export interface AccessEventsFilter {
  dateRange?: AccessEventsDateRange | null;
  actions?: (MobileOpCode | null)[] | null;
  visitorIds?: string[] | null;
  doorIds?: string[] | null;
  reason?: string | null;
}

export interface LocationInput {
  name: string;
  type: LocationType;
  doorIds: string[];
}

export interface OfficeDayDateRange {
  rangeStart: GraphQL_AWSDateTime;
  rangeEnd: GraphQL_AWSDateTime;
}

export interface OfficeDayFilters {
  dateRange?: OfficeDayDateRange | null;
}

export interface OfficeDayInput {
  date: GraphQL_AWSDate;
}

export interface UpdateAccessGrantInput {
  scheduleType: string;
  weekSchedule: WeekScheduleDayInput[];
}

export interface UpdateDoorOfficeModeInput {
  scheduleType: string;
  weekSchedule: WeekScheduleDayInput[];
}

export interface VisitorGroupMembershipInput {
  visitorGroupExternalRef: string;
  role: Role;
}

export interface VisitorGroupsFilter {
  name?: string | null;
  type?: string | null;
}

export interface VisitsDateRange {
  rangeStart: GraphQL_AWSDateTime;
  rangeEnd?: GraphQL_AWSDateTime | null;
}

export interface VisitsFilter {
  dateRange?: VisitsDateRange | null;
  hasAttendees?: boolean | null;
}

export interface VisitsSort {
  field: VisitsSortFields;
  direction: SortDirection;
}

export interface WeekScheduleDayInput {
  dayName: string;
  from: string;
  to: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
