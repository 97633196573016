import React from 'react';
import styled from 'styled-components';
import Icon from 'ui/atoms/Icon/Icon';
import Typography from 'ui/atoms/Typography/Typography';
import ComponentWrapper from 'ui/templates/ComponentWrapper/ComponentWrapper';

const IconWrapper = styled.div`
  margin: auto;
  margin-bottom: 1.75rem;
`;

const TitleWrapper = styled.div`
  margin: auto;
  margin-bottom: 1.5rem;
`;

const TextWrapper = styled.div`
  width: 100%;
  text-align: center;
`;

const FilterNoResult: React.FC = () => (
  <ComponentWrapper flexDirection="column" width="20.125rem" justifyContent="center" alignSelf="auto">
    <IconWrapper>
      <Icon name="Search" width={54} height={54} viewBox="0 0 11 11" id="filter-no-result-icon" />
    </IconWrapper>
    <TitleWrapper>
      <Typography variant="title4" id="filter-no-result-title">
        No results
      </Typography>
    </TitleWrapper>
    <TextWrapper>
      <Typography variant="body4" id="filter-no-result-subtitle">
        This may be the answer you’re looking for. If not, try removing or changing your filters to make them less
        specific.
      </Typography>
    </TextWrapper>
  </ComponentWrapper>
);

export default FilterNoResult;
