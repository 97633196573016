import { LockWithGroupsForm } from 'components/Drawer/types';
import React from 'react';
import { Control, Controller, UseFormRegister } from 'react-hook-form';
import Input from 'ui/molecules/Input/Input';
import Label from 'ui/atoms/Label/Label';
import Typography from 'ui/atoms/Typography/Typography';
import Select, { OptionProps } from 'ui/molecules/Select/Select';
import ComponentWrapper from 'ui/templates/ComponentWrapper/ComponentWrapper';
import styled from 'styled-components';
import Tooltip from 'ui/atoms/Tooltip/Tooltip';
import Icon from 'ui/atoms/Icon/Icon';
import Switch from 'ui/atoms/Switch/Switch';
import OfficeModeTooltipInfo from 'ui/organisms/OfficeModeTooltipInfo/OfficeModeTooltipInfo';
import { NameUniqueInputValidation, OfficeModeTimeInputValidation } from 'components/Drawer/variants/validationTexts';
import { BatteryStatus } from 'hooks/useBatteryStatusUI/useBatteryStatusUI';
import BatteryStatusBox from 'ui/molecules/BatteryStatusBox/BatteryStatusBox';
import Multiselect, { Value } from 'components/Multiselect/Multiselect';

const IconWrapper = styled.div`
  cursor: pointer;
  align-items: center;
`;

export interface Props {
  register: UseFormRegister<LockWithGroupsForm>;
  defaultValues: LockWithGroupsForm;
  selectOption: OptionProps<string>[];
  selectValue: null | string;
  inputValue: undefined | string;
  handleSelectInputChange: (value: string) => void;
  handleSelectChange: (value: OptionProps<string> | null) => void;
  nameInputValue: string;
  handleNameInputOnChange: (value: string) => void;
  control: Control<LockWithGroupsForm, object>;
  switchIsOfficeModeEnabled?: boolean;
  handleSwitchIsOfficeModeEnabledOnChange?: () => void;
  inputOfficeModeFromValue?: string;
  handleInputOfficeModeFromValueOnChange?: (value: string) => void;
  inputOfficeModeToValue?: string;
  handleInputOfficeModeToValueOnChange?: (value: string) => void;
  inputValidationFailed?: boolean;
  showValidation: boolean;
  nameInputValidation: NameUniqueInputValidation;
  officeModeTimeInputValidation: OfficeModeTimeInputValidation;
  inputOnKeyDown: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  batteryStatus: BatteryStatus;
  handleGroupsOnChange: (value: Value[]) => void;
  groupsOptions: Value[];
  groupsDefaultValue: Value[];
}

const LockWithGroups: React.FC<Props> = ({
  register,
  defaultValues,
  selectOption,
  selectValue,
  inputValue,
  handleSelectInputChange,
  control,
  handleSelectChange,
  nameInputValue,
  handleNameInputOnChange,
  switchIsOfficeModeEnabled,
  handleSwitchIsOfficeModeEnabledOnChange,
  inputOfficeModeFromValue,
  handleInputOfficeModeFromValueOnChange,
  inputOfficeModeToValue,
  handleInputOfficeModeToValueOnChange,
  showValidation,
  nameInputValidation,
  officeModeTimeInputValidation,
  inputOnKeyDown,
  batteryStatus,
  handleGroupsOnChange,
  groupsOptions,
  groupsDefaultValue
}) => (
  <>
    <div>
      <Label>Serial number</Label>
      <Typography variant="body1" color="dTextHigh" id="drawer-serial-number">
        {defaultValues.domSerialNumber}
      </Typography>
    </div>
    <Input
      {...register('name')}
      onChange={(event) => handleNameInputOnChange(event.target.value)}
      customRequired
      label="Name*"
      failed={showValidation && nameInputValidation !== undefined}
      value={nameInputValue}
      failedText={nameInputValidation}
      failedId="drawer-input-name-validation"
      id="drawer-input-name"
    />
    <Controller
      control={control}
      defaultValue={selectValue || undefined}
      name="locationId"
      render={() => (
        <Select
          fieldTemplateProps={{ label: 'Room' }}
          autocompleteProps={{
            options: selectOption,
            value: selectValue,
            inputValue,
            onInputChange: (_, value) => {
              if (value !== 'undefined') handleSelectInputChange(value);
            },
            onChange: (_, value) => {
              handleSelectChange(value as OptionProps<string>);
            },
            // @ts-ignore
            getOptionLabel: (option) => option.value as string,
            id: 'drawer-select-location-id',
            onKeyDown: inputOnKeyDown
          }}
          textFieldProps={{ placeholder: 'No room selected' }}
          {...register('locationId')}
        />
      )}
    />
    <Multiselect
      {...register('name')}
      handleValueOnChange={handleGroupsOnChange}
      label="Lock Group(s)*"
      placeholder="Select group"
      options={groupsOptions}
      defaultValue={groupsDefaultValue}
      id="drawer-input-lock-groups"
      hideClearAllButton
    />
    {handleInputOfficeModeFromValueOnChange && handleInputOfficeModeToValueOnChange && (
      <Controller
        control={control}
        name="isOfficeModeEnabled"
        render={() => (
          <ComponentWrapper flexDirection="column" gap="1rem">
            <ComponentWrapper flexDirection="row" gap="1rem" justifyContent="space-between" alignItems="center">
              <ComponentWrapper gap="1rem" alignItems="center">
                <Typography variant="body1" color="dTextHigh">
                  Enable office mode
                </Typography>
                <Tooltip elementOnHover={<OfficeModeTooltipInfo />} id="office-mode-tooltip">
                  <IconWrapper>
                    <Icon name="Info" id="locks-i-icon-office-mode" />
                  </IconWrapper>
                </Tooltip>
              </ComponentWrapper>
              <Switch
                variant="dark"
                {...register('isOfficeModeEnabled')}
                id="drawer-enable-office-mode-span"
                inputId="drawer-enable-office-mode-input"
                checked={switchIsOfficeModeEnabled}
                onClick={handleSwitchIsOfficeModeEnabledOnChange}
                onKeyDown={inputOnKeyDown}
              />
            </ComponentWrapper>
            <ComponentWrapper flexDirection="row" gap="2rem">
              <ComponentWrapper flexDirection="column" width="10.25rem">
                <Typography variant="label" color="dTextHigh">
                  start time
                </Typography>
                <Input
                  type="time"
                  customRequired
                  value={inputOfficeModeFromValue}
                  onChange={(event) => handleInputOfficeModeFromValueOnChange(event.target.value)}
                  disabled={!switchIsOfficeModeEnabled}
                  failed={showValidation && officeModeTimeInputValidation !== undefined}
                  hideValidationText
                  hideValidationEmptySpace
                  id="office-mode-input-from"
                  onKeyDown={inputOnKeyDown}
                />
              </ComponentWrapper>
              <ComponentWrapper flexDirection="column" width="10.25rem">
                <Typography variant="label" color="dTextHigh">
                  end time
                </Typography>
                <Input
                  type="time"
                  value={inputOfficeModeToValue}
                  onChange={(event) => handleInputOfficeModeToValueOnChange(event.target.value)}
                  disabled={!switchIsOfficeModeEnabled}
                  failed={showValidation && officeModeTimeInputValidation !== undefined}
                  hideValidationText
                  hideValidationEmptySpace
                  id="office-mode-input-to"
                  customRequired
                  onKeyDown={inputOnKeyDown}
                />
              </ComponentWrapper>
            </ComponentWrapper>
            {showValidation && officeModeTimeInputValidation !== undefined && (
              <ComponentWrapper justifyContent="end">
                <Typography variant="label" color="error" id="office-mode-inputs-validation-text">
                  {officeModeTimeInputValidation}
                </Typography>
              </ComponentWrapper>
            )}
          </ComponentWrapper>
        )}
      />
    )}
    <ComponentWrapper flexDirection="column" gap="1rem">
      <Label>Status</Label>
      <BatteryStatusBox batteryStatus={batteryStatus} />
    </ComponentWrapper>
  </>
);

export default LockWithGroups;
