import React from 'react';
import styled from 'styled-components';
import Typography from 'ui/atoms/Typography/Typography';
import { BORDER_RADIUS_MULTIPLIER } from 'ui/shared/const';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from 'environment/SsoConfig';
import { useReactiveVar } from '@apollo/client';
import { uiVar } from 'state/vars';
import MSButton from 'ui/atoms/MSButton/MSButton';
import BackgroundImage from 'ui/atoms/BackgroundImage/BackgroundImage';
import ComponentWrapper from 'ui/templates/ComponentWrapper/ComponentWrapper';

const LoginWrapper = styled.div`
  backdrop-filter: blur(4px);
  border-radius: ${(props) => props.theme.borderRadius * BORDER_RADIUS_MULTIPLIER}px;
  border: 2px solid ${(props) => props.theme.colors.primary};
  background: rgba(255, 255, 255, 0.15);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${({ theme: { isMobile, isMobileLandscape } }) => {
    if (isMobile)
      if (isMobileLandscape)
        return `
          padding: 0 12%;
          margin: auto 1.5rem;
          height: calc(100vh - 3rem);`;
      else
        return `
          padding: 10vh 3rem;
          margin: 1.5rem auto;
          width: calc(100vw - 3rem);`;
    return `
      margin: auto;
      margin-top: 23vh;
      padding: 6.875rem 3rem 4.375rem 3rem;
      min-height: 34.375rem;
      min-width: 26rem;`;
  }};
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-end;

  span {
    color: ${({ theme }) => theme.colors.primary};
    ${({ theme }) => theme.typography.variant('title1')}
  }

  ${({ theme: { isMobile } }) =>
    isMobile
      ? `
    flex-wrap: wrap;
    align-items: center;
    text-align: center;
    justify-content: center;
    `
      : 'width: 26rem;'};
`;

const SubTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  span {
    color: ${({ theme }) => theme.colors.primary};
  }

  ${({ theme: { isMobile } }) =>
    isMobile
      ? `
      margin-top:5vh;
    `
      : 'width: 26rem;'};
`;

const ButtonWrapper = styled.div`
  margin-top: 8.125rem;
  ${({ theme: { isMobile } }) =>
    isMobile
      ? `
      margin-top:5vh;
    `
      : ''};
`;

const BottomLinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${({ theme: { isMobile } }) =>
    isMobile
      ? `
      margin-top:5vh;
    `
      : `margin-top:2.5rem;
      width: 26rem;`};
`;

const StyledLink = styled.a`
  text-decoration: none;
`;

const Login: React.FC = () => {
  const { isMobile } = useReactiveVar(uiVar);
  const { instance } = useMsal();

  const handleLogin = async () => {
    await instance.loginRedirect(loginRequest);
  };

  return (
    <ComponentWrapper width="100vw" height="100vh" alignItems="center" justifyContent="center">
      <BackgroundImage id="background" />
      <LoginWrapper data-testid="login-window" id="login-window">
        <TitleWrapper id="welcome-message">
          <Typography variant="title2" color="lTextHigh">
            Welcome to&nbsp;<span>Atlas</span>
          </Typography>
        </TitleWrapper>
        <SubTitleWrapper id="powered-by">
          <Typography variant="body4" color="lTextHigh">
            Powered&nbsp;by <span>TKH&nbsp;Security</span>
          </Typography>
        </SubTitleWrapper>
        <ButtonWrapper data-testid="login-button" id="login-button">
          <MSButton onClick={handleLogin} isMobile={isMobile}>
            Sign&nbsp;in
          </MSButton>
        </ButtonWrapper>
        <BottomLinksWrapper>
          <Typography variant="body4" color="lTextMedium">
            By continuing, you agree to Atlas&apos;s
          </Typography>
          <ComponentWrapper flexDirection="row" columnGap="0.25rem">
            <StyledLink
              target="_blank"
              href="https://tkhsecurity.com/privacy/terms-and-conditions-xenia-access-control-eng/"
            >
              <Typography variant="body4" color="primary">
                Terms of Service
              </Typography>
            </StyledLink>
            <Typography variant="body4" color="lTextMedium">
              &
            </Typography>
            <StyledLink
              target="_blank"
              href="https://tkhsecurity.com/privacy/privacy-statement-xenia-access-control-eng/"
            >
              <Typography variant="body4" color="primary">
                Privacy Statement.
              </Typography>
            </StyledLink>
          </ComponentWrapper>
        </BottomLinksWrapper>
      </LoginWrapper>
    </ComponentWrapper>
  );
};

export default Login;
