import React from 'react';
import styled from 'styled-components';
import Typography from 'ui/atoms/Typography/Typography';
import BatteryStatusItem from 'ui/molecules/BatteryStatusItem/BatteryStatusItem';

const Container = styled.div`
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.bgDark};
  border: 1px solid ${({ theme }) => theme.colors.b4};
  border-radius: ${({ theme }) => theme.borderRadius}px;
  width: 28rem;
  height: 18.75rem;
`;

const BatteryStatus: React.FC = () => (
  <Container>
    <Typography variant="title4" color="lTextMedium">
      Battery status
    </Typography>
    <BatteryStatusItem status="BatteryEmpty" />
    <BatteryStatusItem status="BatteryCritical" />
    <BatteryStatusItem status="BatteryLow" />
    <BatteryStatusItem status="BatteryUnknown" />
    <BatteryStatusItem status="BatteryDefault" />
    <BatteryStatusItem status="BatteryPowered" />
  </Container>
);

export default BatteryStatus;
