import React from 'react';
import styled from 'styled-components';
import Typography from 'ui/atoms/Typography/Typography';
import { BORDER_RADIUS_MULTIPLIER } from 'ui/shared/const';
import { useReactiveVar } from '@apollo/client';
import BackgroundImage from 'ui/atoms/BackgroundImage/BackgroundImage';
import GooglePlay from 'assets/images/GooglePlay.png';
import AppStore from 'assets/images/AppStore.png';
import QRCode from 'react-qr-code';
import { uiVar } from 'state/vars';
import { useSearchParams } from 'react-router-dom';
import QRCodeValueUtil from 'utils/QRCodeValue/QRCodeValue.util';
import ComponentWrapper from 'ui/templates/ComponentWrapper/ComponentWrapper';
import Loader from 'ui/atoms/Loader/Loader';
import Title from 'components/Title/Title';

type LandingType = 'access' | 'config';
export interface Props {
  type: LandingType;
}

const Container = styled.div`
  ${({ theme: { isMobileLandscape } }) => (isMobileLandscape ? 'overflow: auto; height: 100vh;' : '')};
`;

const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  gap: 2rem;

  ${({ theme: { isMobile } }) => (isMobile ? `margin: 2rem 0;` : ``)};
`;

const Frame = styled.div`
  background-color: ${(props) => props.theme.colors.bgLight};
  display: flex;
  flex-direction: col;
  gap: 4.375rem;
  border-radius: ${(props) => props.theme.borderRadius * BORDER_RADIUS_MULTIPLIER}px;

  ${({ theme: { isMobile } }) =>
    isMobile
      ? `flex-direction: column;
  padding: 1.5rem;
  gap: 1.5rem;
  margin-top: 2rem;`
      : `flex-direction: row;
  padding: 3rem 5rem;
  gap: 4.375rem;`};
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  ${({ theme: { isMobile } }) => (isMobile ? `width: calc(90vw - 3rem);` : 'width: 26.375rem;')};
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  ${({ theme: { isMobile } }) => (isMobile ? `flex-direction: row;` : 'flex-direction: column;')};
`;

const GooglePlayButton = styled.div`
  background-image: url(${GooglePlay});
  width: 7.5rem;
  height: 2.1875rem;
`;

const AppStoreButton = styled.div`
  background-image: url(${AppStore});
  width: 7.5rem;
  height: 2.5rem;
`;

const LandingPage: React.FC<Props> = ({ type }) => {
  const { isMobile } = useReactiveVar(uiVar);
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');

  return (
    <Container>
      <BackgroundImage id="background" />
      <Wrapper>
        {!isMobile && <Title />}
        <Frame>
          <ComponentWrapper flexDirection="column" gap="1.5rem">
            <Typography variant={isMobile ? 'title4' : 'title1'} color="dTextHigh">
              ATLAS {type === 'access' ? 'Access' : 'Config'} Application
            </Typography>
            {isMobile ? (
              <TextWrapper>
                {type === 'access' ? (
                  <Typography variant="body4" color="dTextHigh">
                    ATLAS uses your mobile device as your personal access card. To continue, it is required that you
                    link your access profile to the ATLAS {type === 'access' ? 'Access' : 'Config'} application.
                  </Typography>
                ) : (
                  <Typography variant="body3" color="dTextHigh">
                    You were invited to become an installer for ATLAS. Simply download ATLAS Config application and
                    click on registration link again to connect your installer profile to your mobile device.
                  </Typography>
                )}
                <Typography variant="body4" color="dTextHigh">
                  Download the <b>ATLAS {type === 'access' ? 'Access' : 'Config'} Application</b> to your mobile device.
                </Typography>
              </TextWrapper>
            ) : (
              <TextWrapper>
                {type === 'access' ? (
                  <Typography variant="body3" color="dTextHigh">
                    ATLAS uses your mobile device as your personal access card. To continue, it is required that you
                    link your access profile to the ATLAS Access application.
                  </Typography>
                ) : (
                  <Typography variant="body3" color="dTextHigh">
                    You were invited to become an installer for ATLAS. Simply scan the QR code provided below with the
                    ATLAS Config application, to connect your installer profile to your mobile device.
                  </Typography>
                )}
                <Typography variant="body3" color="dTextHigh">
                  1. Download the <b>Atlas {type === 'access' ? 'Access' : 'Config'} App</b> to your mobile device.
                </Typography>
                <Typography variant="body3" color="dTextHigh">
                  2. Follow the onboarding steps and open the in-application <b>QR Scanner</b>.
                </Typography>
                <Typography variant="body3" color="dTextHigh">
                  3. <b>Scan the QR code</b> to register your mobile device. Registering can take up <b>60 seconds</b>.
                </Typography>
              </TextWrapper>
            )}
          </ComponentWrapper>
          <ComponentWrapper flexDirection="column" gap="5rem">
            {code ? (
              !isMobile && (
                <ComponentWrapper
                  alignItems="center"
                  justifyContent="center"
                  width="14rem"
                  height="14rem"
                  id="registration-page-qr-code"
                >
                  <QRCode value={QRCodeValueUtil.getQrCodeValue(code)} />
                </ComponentWrapper>
              )
            ) : (
              <ComponentWrapper
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                width="14rem"
                height="14rem"
              >
                <Loader size={64} />
                <Typography variant="title4" color="dTextHigh">
                  Generating QR
                </Typography>
              </ComponentWrapper>
            )}
            <ButtonsWrapper>
              {type === 'access' && (
                <a
                  href="https://play.google.com/store/apps/details?id=com.tkhsecurity.siqura&hl=en_US/"
                  target="_blank"
                  rel="noreferrer"
                  id="google-play-button"
                >
                  <GooglePlayButton />
                </a>
              )}
              <a
                href={
                  type === 'access'
                    ? 'https://apps.apple.com/eng/app/atlas-access/id1625855308'
                    : 'https://apps.apple.com/nl/app/atlas-config/id1634787760?l'
                }
                target="_blank"
                rel="noreferrer"
                id="app-store-button"
              >
                <AppStoreButton />
              </a>
            </ButtonsWrapper>
          </ComponentWrapper>
        </Frame>
      </Wrapper>
    </Container>
  );
};

export default LandingPage;
