import React from 'react';
import QRCode from 'react-qr-code';
import styled from 'styled-components';
import Icon from 'ui/atoms/Icon/Icon';
import Label from 'ui/atoms/Label/Label';
import Loader from 'ui/atoms/Loader/Loader';
import Typography from 'ui/atoms/Typography/Typography';
import ComponentWrapper from 'ui/templates/ComponentWrapper/ComponentWrapper';

export interface Props {
  qrCodeValue: null | string;
}

const QRCodeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14rem;
  height: 14rem;
  margin: auto;
`;

const MyProfileStatusDisconnected: React.FC<Props> = ({ qrCodeValue }) => (
  <ComponentWrapper flexDirection="column" gap="1.5rem">
    <Label>Status</Label>
    <Icon name="StatusDisconnected" color="error" width={92} height={12} />
    <Label>Qr Code</Label>
    {qrCodeValue ? (
      <QRCodeWrapper id="drawer-qr-code">
        <QRCode value={qrCodeValue} />
      </QRCodeWrapper>
    ) : (
      <ComponentWrapper
        alignSelf="center"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        width="14rem"
        height="14rem"
        id="drawer-qr-code-loading"
      >
        <Loader size={64} />
        <Typography variant="title4" color="dTextHigh">
          Generating QR
        </Typography>
      </ComponentWrapper>
    )}
    <ComponentWrapper flexDirection="column" alignItems="center" justifyContent="center">
      <Typography variant="body3" color="dTextHigh">
        Scan the QR code with the Atlas Access
      </Typography>
      <Typography variant="body3" color="dTextHigh">
        application, to connect your mobile device to
      </Typography>
      <Typography variant="body3" color="dTextHigh">
        your Atlas Access profile
      </Typography>
    </ComponentWrapper>
  </ComponentWrapper>
);

export default MyProfileStatusDisconnected;
