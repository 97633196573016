/* eslint-disable import/prefer-default-export */
import { TabsType } from 'components/Tabs/Tabs';

export const usersTabs: TabsType[] = [
  {
    text: 'External groups',
    location: '/users/external-groups'
  },
  {
    text: 'Installers',
    location: '/users/Installers'
  }
];
