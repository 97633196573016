/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-magic-numbers */
import React, { useState } from 'react';
import Search from 'ui/molecules/Search/Search';
import styled from 'styled-components';
import Drawer from 'components/Drawer/Drawer';
import PageContent from 'ui/templates/PageContent/PageContent';
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar';
import Table from 'ui/organisms/Table/Table';
import TableHeaderRow from 'ui/molecules/TableHeaderRow/TableHeaderRow';
import TableHeader from 'ui/atoms/TableHeader/TableHeader';
import TableRow from 'ui/molecules/TableRow/TableRow';
import TableCell from 'ui/atoms/TableCell/TableCell';
import ArrayUtil from 'utils/Array/Array.util';
import ComponentWrapper from 'ui/templates/ComponentWrapper/ComponentWrapper';
import useInstallers from 'hooks/useInstallers/useInstallers';
import TableCellIcon from 'ui/atoms/TableCellIcon/TableCellIcon';
import useDrawer from 'hooks/useDrawer/useDrawer';
import { EditInstallerForm } from 'components/Drawer/types';
import NoInstallers from 'ui/molecules/NoInstallers/NoInstallers';
import Modal from 'components/Modal/Modal';
import useSortingInstallersTable from 'hooks/useSorting/useSortingInstallersTable/useSortingInstallersTable';
import { StickyToTop } from 'ui/templates/StickyToTop/StickyToTop';
import { kebabCase } from 'lodash';
import { usersTabs } from '../usersTabsConst';

const SearchWrapper = styled.div`
  margin: 1rem 0 2rem 0;
  width: 14.875rem;
`;

const TableWrapper = styled.div`
  height: calc(100vh - 20rem);
  width: calc(100vw - 25rem);
  margin-right: 1rem;
`;

export const headers = ['Installer', 'ATLAS Access app', 'ATLAS Config app'] as const;

const Installers = (): JSX.Element => {
  const [filterStr, setFilterStr] = useState('');
  const { showDrawer } = useDrawer();
  const enqueueSnackbar = useEnqueueSnackbar();
  const handleFetchError = (errorMessage: string) => enqueueSnackbar(errorMessage, { snackbartype: 'error' });
  const skeletonArray = ArrayUtil.SkeletonArray();
  const { installersGroups, loading: installersLoading } = useInstallers({
    handleFetchError
  });
  const { handleSortingIcon, handleSortingOnClick } = useSortingInstallersTable();

  const handleAddInstaller = () => {
    showDrawer({
      type: 'addInstaller',
      contentValue: {}
    });
  };

  const handleRowClick = (installer: EditInstallerForm) => {
    showDrawer({
      type: 'editInstaller',
      contentValue: {
        ...installer
      }
    });
  };

  const getFilterData = () => {
    if (installersGroups && installersGroups[0])
      if (filterStr !== '')
        return installersGroups[0].members.filter((element) =>
          [
            element.name,
            element.email,
            (element.mobileDevices.some((device) => device.role === 'CONFIG' && device.isRegistered)
              ? 'connected'
              : '') ||
              (!element.mobileDevices.some((device) => device.role === 'CONFIG' && device.isRegistered)
                ? 'disconnected'
                : ''),
            (element.mobileDevices.some((device) => device.role === 'ACCESS' && device.isRegistered)
              ? 'connected'
              : '') ||
              (!element.mobileDevices.some((device) => device.role === 'ACCESS' && device.isRegistered)
                ? 'disconnected'
                : '')
          ].some((key) => key && key.toLowerCase().includes(filterStr.toLowerCase()))
        );
      else return installersGroups[0].members;
    return [];
  };

  const isTableVisible = () => installersLoading || (!installersLoading && installersGroups.length > 0);
  const isTableLoadingVisible = () => installersLoading;
  const isTableRowsWithDataVisible = () => !installersLoading && installersGroups.length > 0;
  const isTableRowsWithNoDataVisible = () =>
    !installersLoading &&
    installersGroups.length > 0 &&
    installersGroups[0] &&
    installersGroups[0].members.length === 0;

  return (
    <PageContent
      title="ATLAS"
      titleId="location"
      tabs={usersTabs}
      buttonText="Add installer"
      buttonOnClick={handleAddInstaller}
      buttonId="add-installer-button"
    >
      <StickyToTop>
        <ComponentWrapper alignItems="center" justifyContent="end" gap="0.5rem">
          <SearchWrapper>
            <Search onChange={(event) => setFilterStr(event.target.value)} value={filterStr} />
          </SearchWrapper>
        </ComponentWrapper>
      </StickyToTop>
      {isTableVisible() && (
        <TableWrapper>
          <Table
            header={
              <TableHeaderRow placeForArrow>
                <TableHeader
                  headerText={headers[0]}
                  id={kebabCase(`header-${headers[0]}`)}
                  flex="0 0 33%"
                  iconSorting={handleSortingIcon(headers[0])}
                  onClick={() => handleSortingOnClick(headers[0])}
                />
                <TableHeader
                  headerText={headers[1]}
                  id={kebabCase(`header-${headers[1]}`)}
                  flex="0 0 33%"
                  iconSorting={handleSortingIcon(headers[1])}
                  onClick={() => handleSortingOnClick(headers[1])}
                />
                <TableHeader
                  headerText={headers[2]}
                  id={kebabCase(`header-${headers[2]}`)}
                  flex="0 0 33%"
                  iconSorting={handleSortingIcon(headers[2])}
                  onClick={() => handleSortingOnClick(headers[2])}
                />
              </TableHeaderRow>
            }
            headerId="lock-headers"
            rowsWrapperId="lock-list"
          >
            {isTableLoadingVisible() &&
              skeletonArray.map((_, index) => (
                <TableRow id={`row-skeleton-${index}`} marginAfterContent key={`skeletonTableRow-${_.id}`}>
                  <TableCell isLoading firstLineText="" flex="0 0 33%" />
                  <TableCell isLoading firstLineText="" flex="0 0 33%" />
                  <TableCell isLoading firstLineText="" flex="0 0 33%" />
                </TableRow>
              ))}
            {isTableRowsWithDataVisible() &&
              getFilterData().map((installer, id) => (
                <TableRow
                  onClick={() =>
                    handleRowClick({
                      email: installer.email,
                      id: installer.id,
                      mobileDevices: installer?.mobileDevices,
                      name: installer.name,
                      isEditable: installer.isEditable!
                    })
                  }
                  id={`row-${id}`}
                  hoverEffect
                  hoverArrow
                  key={installer.id}
                >
                  <TableCell
                    firstLineText={installer.name}
                    firstLineId={`row-installer-${id}-name`}
                    secondLineText={installer.email || undefined}
                    secondLineId={`row-installer-${id}-email`}
                    flex="0 0 33%"
                  />

                  <TableCellIcon
                    iconProps={{
                      name: installer?.mobileDevices?.find((device) => device.role === 'ACCESS' && device.isRegistered)
                        ?.isRegistered
                        ? 'StatusConnected'
                        : 'StatusDisconnected',
                      color: installer?.mobileDevices?.find((device) => device.role === 'ACCESS' && device.isRegistered)
                        ?.isRegistered
                        ? 'success'
                        : 'error',
                      width: 92,
                      height: 12,
                      id: `row-installer-${id}-status-access`
                    }}
                    flex="0 0 33%"
                  />
                  <TableCellIcon
                    iconProps={{
                      name: installer?.mobileDevices?.find((device) => device.role === 'CONFIG' && device.isRegistered)
                        ?.isRegistered
                        ? 'StatusConnected'
                        : 'StatusDisconnected',
                      color: installer?.mobileDevices?.find((device) => device.role === 'CONFIG' && device.isRegistered)
                        ?.isRegistered
                        ? 'success'
                        : 'error',
                      width: 92,
                      height: 12,
                      id: `row-installer-${id}-status-config`
                    }}
                    flex="0 0 33%"
                  />
                </TableRow>
              ))}
            {isTableRowsWithNoDataVisible() && (
              <ComponentWrapper
                height="calc(100vh - 20rem)"
                width="calc(100vw - 25rem)"
                margin="1rem"
                justifyContent="center"
                alignItems="center"
              >
                <NoInstallers handleOnButtonClick={handleAddInstaller} />
              </ComponentWrapper>
            )}
          </Table>
        </TableWrapper>
      )}

      <Drawer />
      <Modal />
    </PageContent>
  );
};

export default Installers;
