import React from 'react';
import styled from 'styled-components';
import Icon from 'ui/atoms/Icon/Icon';
import Typography from 'ui/atoms/Typography/Typography';
import useBatteryStatusUI, { BatteryStatus } from 'hooks/useBatteryStatusUI/useBatteryStatusUI';
import ComponentWrapper from 'ui/templates/ComponentWrapper/ComponentWrapper';

export interface Props {
  status: BatteryStatus;
}

interface IconWrapperProps {
  opacity: null | string;
}

const IconWrapper = styled.div<IconWrapperProps>`
  align-items: center;
  justify-content: center;
  opacity: ${({ opacity }) => opacity};
  width: 1.5rem;
  height: 1.5rem;
`;

const TypographyWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const BatteryStatusItem: React.FC<Props> = ({ status }) => {
  const { getBatteryData } = useBatteryStatusUI();

  return (
    <ComponentWrapper alignItems="center" gap="0.5rem">
      <IconWrapper opacity={getBatteryData(status).iconOpacity}>
        <Icon name={status} color={getBatteryData(status).iconColor} />
      </IconWrapper>
      <TypographyWrapper>
        <Typography variant="body4" color="lTextMedium">
          {getBatteryData(status).firstLineText}
        </Typography>
        <Typography variant="helper" color="error">
          {getBatteryData(status).secondLineText}
        </Typography>
      </TypographyWrapper>
    </ComponentWrapper>
  );
};

export default BatteryStatusItem;
