import { EditInstallerForm } from 'components/Drawer/types';
import useDrawer from 'hooks/useDrawer/useDrawer';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { EditInstallerDrawer, MobileType } from 'state/types';
import Icon from 'ui/atoms/Icon/Icon';
import Typography from 'ui/atoms/Typography/Typography';
import DrawerTemplate from 'ui/organisms/Drawer/DrawerTemplate';
import EditInstaller from 'ui/organisms/Drawer/variants/EditInstaller';
import ComponentWrapper from 'ui/templates/ComponentWrapper/ComponentWrapper';
import useModal from 'hooks/useModal/useModal';
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar';
import useInstallers from 'hooks/useInstallers/useInstallers';
import { formKeyDownHandler } from 'utils/InputOnKeyDown/formKeyDownHandler.util';
import { NameInputValidation } from '../validationTexts';

const EditInstallerVariant: React.FC = () => {
  const { register, handleSubmit, setValue } = useForm<EditInstallerForm>();
  const { drawerState, hideDrawer } = useDrawer<EditInstallerDrawer>();
  const { showModal } = useModal();
  const [nameInputValue, setNameInputValue] = useState<string>();
  const [emailInputValue, setEmailInputValue] = useState<string>();
  const [mobileDeviceStatusAccess, setMobileDeviceStatusAccess] = useState<boolean>();
  const [mobileDeviceStatusConfig, setMobileDeviceStatusConfig] = useState<boolean>();
  const [nameInputValidation, setNameInputValidation] = useState<NameInputValidation>(undefined);
  const [showValidation, setShowValidation] = useState(false);
  const enqueueSnackbar = useEnqueueSnackbar();
  const handleFetchError = (errorMessage: string) => enqueueSnackbar(errorMessage, { snackbartype: 'error' });
  const { updateInstallerMember, updateInstallerMemberLoading } = useInstallers({
    handleFetchError
  });

  const onSubmit = async () => {
    setShowValidation(true);
    if (!updateInstallerMemberLoading && emailInputValue && nameInputValue && nameInputValidation === undefined) {
      updateInstallerMember(emailInputValue, nameInputValue);
      hideDrawer();
    }
  };

  const handleDeleteInstaller = () => {
    showModal({ type: 'deleteInstaller' });
  };

  const handleOpenResendInvitationModal = (type: MobileType) => {
    showModal({
      type: 'resendInvitation',
      contentValue: {
        visitorId: drawerState.contentValue.id,
        email: emailInputValue!,
        mobileDevices: drawerState.contentValue.mobileDevices,
        type
      }
    });
  };

  useEffect(() => {
    if (drawerState.contentValue) {
      if (nameInputValue === undefined) setNameInputValue(drawerState.contentValue.name);
      if (emailInputValue === undefined) setEmailInputValue(drawerState.contentValue.email || undefined);
      if (mobileDeviceStatusConfig === undefined)
        setMobileDeviceStatusConfig(
          drawerState.contentValue.mobileDevices.find((device) => device.role === 'CONFIG')?.isRegistered
        );
      if (mobileDeviceStatusAccess === undefined)
        setMobileDeviceStatusAccess(
          drawerState.contentValue.mobileDevices.find((device) => device.role === 'ACCESS')?.isRegistered
        );
    }
  }, [drawerState, emailInputValue, mobileDeviceStatusAccess, mobileDeviceStatusConfig, nameInputValue]);

  useEffect(() => {
    if (nameInputValue === '') setNameInputValidation('Name cannot be empty');
    else setNameInputValidation(undefined);
  }, [nameInputValue]);

  useEffect(() => {
    setValue('name', nameInputValue!);
    setValue('email', emailInputValue!);
  }, [emailInputValue, nameInputValue, setValue]);
  return (
    <DrawerTemplate
      title="Edit installer"
      headerSideText={
        <ComponentWrapper id="drawer-delete-installer">
          <Typography variant="clickable" color="dTextHigh">
            DELETE
          </Typography>
          <Icon name="DeleteBin" color="dTextHigh" height="16" width="16" data-testid="svg-delete" />
        </ComponentWrapper>
      }
      headerSideTextOnClick={handleDeleteInstaller}
      confirmButtonText="Save"
      id="drawer-edit-installer"
      onSubmit={handleSubmit(onSubmit)}
      disableConfirmButton={
        !drawerState.contentValue.isEditable || (showValidation && nameInputValidation !== undefined)
      }
    >
      <EditInstaller
        register={register}
        nameInputValue={nameInputValue!}
        handleNameInputOnChange={setNameInputValue}
        nameInputValidation={nameInputValidation}
        emailInputValue={emailInputValue!}
        statusAccess={mobileDeviceStatusAccess!}
        statusConfig={mobileDeviceStatusConfig!}
        showValidation={showValidation}
        isEditable={drawerState.contentValue.isEditable}
        isResendInvitationButtonVisibleConfig={!mobileDeviceStatusConfig!}
        isResendInvitationButtonVisibleAccess={!mobileDeviceStatusAccess!}
        handleOnResendInvitationClick={handleOpenResendInvitationModal}
        inputOnKeyDown={(event) => formKeyDownHandler(event, onSubmit)}
      />
    </DrawerTemplate>
  );
};
export default EditInstallerVariant;
