import React from 'react';
import Modal from 'ui/atoms/Modal/Modal';
import Button from 'ui/atoms/Button/Button';
import Typography from 'ui/atoms/Typography/Typography';
import Icon from 'ui/atoms/Icon/Icon';
import useModal from 'hooks/useModal/useModal';
import ComponentWrapper from 'ui/templates/ComponentWrapper/ComponentWrapper';
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar';
import { EditExternalUserDrawer } from 'state/types';
import useDrawer from 'hooks/useDrawer/useDrawer';
import useExternalUsers from 'hooks/useExternalUsers/useExternalUsers';

const DeleteExternalUser: React.FC = () => {
  const { hideModal } = useModal();
  const { hideDrawer } = useDrawer();
  const { drawerState } = useDrawer<EditExternalUserDrawer>();
  const enqueueSnackbar = useEnqueueSnackbar();
  const handleFetchError = (errorMessage: string) => enqueueSnackbar(errorMessage, { snackbartype: 'error' });
  const { deleteVisitorById, deleteVisitorByIdMutationLoading } = useExternalUsers({
    handleFetchError
  });

  const handleDeleteExternalUser = () => {
    if (!deleteVisitorByIdMutationLoading) {
      deleteVisitorById(drawerState.contentValue.id);
      hideModal();
      hideDrawer();
    }
  };

  return (
    <Modal>
      <ComponentWrapper
        flexDirection="column"
        width="36rem"
        gap="2rem"
        margin="1rem 0"
        justifyContent="center"
        alignItems="center"
      >
        <Icon name="DeleteBin" color="dTextHigh" width={40} height={40} viewBox="0 0 24 24" id="delete-bin-icon" />
        <ComponentWrapper flexDirection="column" justifyContent="center" alignItems="center">
          <ComponentWrapper flexDirection="column" justifyContent="center" alignItems="center">
            <Typography variant="title2" color="dTextHigh">
              Are you sure that you want
            </Typography>
            <Typography variant="title2" color="dTextHigh">
              to delete this external group member?
            </Typography>
          </ComponentWrapper>
          <ComponentWrapper flexDirection="column" justifyContent="center" alignItems="center">
            <Typography variant="body3" color="dTextHigh">
              The external group member will no longer have
            </Typography>
            <Typography variant="body3" color="dTextHigh">
              access to your organization
            </Typography>
          </ComponentWrapper>
        </ComponentWrapper>
        <ComponentWrapper width="100%">
          <Button backgroundColor="transparent" textColor="b1" onClick={hideModal} id="cancel-button-modal">
            No, Thank you
          </Button>
          <Button onClick={handleDeleteExternalUser} id="ok-button-modal">
            Yes, i&apos;m sure
          </Button>
        </ComponentWrapper>
      </ComponentWrapper>
    </Modal>
  );
};

export default DeleteExternalUser;
