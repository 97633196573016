import { isEqual, orderBy } from 'lodash';
import { useEffect, useState } from 'react';
import { headers } from 'pages/Users/Installers/Installers';
import { useReactiveVar } from '@apollo/client';
import { installersGroupsVar } from 'state/vars';
import { GetVisitorGroupsForTenantWithMembers_getVisitorGroupsForTenant_members } from 'graphql/generated/GetVisitorGroupsForTenantWithMembers';
import { setInstallersGroupsAction } from 'state/actions/installersGroups';

interface Sorting {
  isAscending?: boolean;
  columnName?: typeof headers[number];
}

export const handleSorting = (
  item: GetVisitorGroupsForTenantWithMembers_getVisitorGroupsForTenant_members,
  columnName?: typeof headers[number]
) => {
  switch (columnName) {
    case 'ATLAS Config app':
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
      return !item.mobileDevices.find(({ role, isRegistered }) => role === 'CONFIG' && isRegistered)?.isRegistered;
    case 'ATLAS Access app':
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
      return !item.mobileDevices.find(({ role, isRegistered }) => role === 'ACCESS' && isRegistered)?.isRegistered;
    case 'Installer':
    default:
      return item.name;
  }
};

export const getSortedArray = (
  installersMembers: GetVisitorGroupsForTenantWithMembers_getVisitorGroupsForTenant_members[],
  sorting: Sorting
) =>
  orderBy(installersMembers, (item) => handleSorting(item, sorting.columnName as typeof headers[number]), [
    sorting.isAscending ? 'asc' : 'desc'
  ]);

const useSortingInstallersTable = () => {
  const installers = useReactiveVar(installersGroupsVar);
  const [sorting, setSorting] = useState<Sorting>({ isAscending: true, columnName: 'Installer' });

  const handleSortingOnClick = (columnName: typeof headers[number]) => {
    if (sorting.isAscending !== undefined && sorting.columnName === columnName) {
      return setSorting({ isAscending: !sorting.isAscending, columnName });
    }
    return setSorting({ isAscending: true, columnName });
  };

  const handleSortingIcon = (columnName: string) => {
    if (sorting.isAscending !== undefined)
      if (sorting.columnName === columnName) return sorting.isAscending ? 'ArrowUp' : 'ArrowDown';
    return undefined;
  };

  useEffect(() => {
    const sortedInstallersGroups = installers.map((group) => ({
      ...group,
      members: getSortedArray(group.members, sorting)
    }));
    if (!isEqual(sortedInstallersGroups, installers)) setInstallersGroupsAction(sortedInstallersGroups);
  }, [installers, sorting]);

  return { handleSortingOnClick, handleSortingIcon };
};

export default useSortingInstallersTable;
