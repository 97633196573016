import React from 'react';
import { Colors } from 'theme/theme';
import styled from 'styled-components';
import TypographySkeleton from '../TypographySkeleton/TypographySkeleton';
import Icon, { Props as IconProps } from '../Icon/Icon';

export interface CssProps {
  firstLineOpacity?: string;
  firstLineColor?: keyof Colors;
  secondLineColor?: keyof Colors;
  flex?: string;
  isLoading?: boolean;
}

export type Props = CssProps & {
  iconProps: Pick<IconProps, 'name' | 'color' | 'fill' | 'viewBox' | 'width' | 'height' | 'id'>;
  id?: string;
};

const TableCellWrapper = styled.div<Partial<CssProps>>`
  display: flex;
  flex-direction: column;
  min-width: 0;

  ${({ flex }) => flex && `flex: ${flex}`};
`;

const TableCellIcon: React.FC<Props> = ({ iconProps, flex = 'auto', id, isLoading = false }) => (
  <TableCellWrapper flex={flex} id={id}>
    {isLoading ? (
      <TypographySkeleton variant="body3">test</TypographySkeleton>
    ) : (
      <>
        <Icon {...iconProps} />
      </>
    )}
  </TableCellWrapper>
);

export default TableCellIcon;
