import { ApolloClient, from, HttpLink, InMemoryCache, split } from '@apollo/client';
import { AUTH_TYPE, createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import { DefinitionNode } from 'graphql';

export const cache: InMemoryCache = new InMemoryCache();

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_XENIA_API_URL as string
});

type CustomDefinitionNode = {
  operation: string;
} & DefinitionNode;

const getApolloClient = (getToken: () => Promise<string>) =>
  new ApolloClient({
    cache,
    link: from([
      createAuthLink({
        url: process.env.REACT_APP_XENIA_API_URL as string,
        auth: {
          type: AUTH_TYPE.AWS_LAMBDA,
          token: getToken
        },
        region: process.env.REACT_APP_AWS_REGION as string
      }),
      split(
        (operations) => {
          const [element] = operations.query.definitions as CustomDefinitionNode[];

          return element.operation !== 'subscription';
        },
        httpLink,
        createSubscriptionHandshakeLink(
          {
            auth: {
              type: AUTH_TYPE.AWS_LAMBDA,
              token: getToken
            },
            region: process.env.REACT_APP_AWS_REGION as string,
            url: `${process.env.REACT_APP_XENIA_API_URL as string}/realtime`
          },
          httpLink
        )
      )
    ])
  });
export default getApolloClient;
