import React from 'react';
import styled from 'styled-components';
import { Colors } from 'theme/theme';
import Typography from '../Typography/Typography';

interface CssProps {
  textColor?: keyof Colors;
  backgroundColor?: keyof Colors;
  availableAfter?: number;
}
export type Props = CssProps &
  React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
    isMobile?: boolean;
  };

const StyledButton = styled.button<Required<Props>>`
  padding: 0.625rem 0;
  height: 2.75rem;
  width: 100%;
  border: none;
  border-radius: ${({ theme }) => theme.borderRadius}px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors[props.backgroundColor]};

  ${({ disabled }) =>
    disabled
      ? `
      `
      : `
      cursor: pointer;
      `};

  ${({ availableAfter, theme }) =>
    availableAfter
      ? `
      background: linear-gradient(to right, ${theme.colors.primary} 50%, ${theme.colors.primaryShade} 0);
      background-size: 200% 100%;
      background-position: right;
      animation: availableAfterAnimation ${availableAfter}s 1s forwards;

      @keyframes availableAfterAnimation {
        100% {
          background-position: left;
        }
      }
      `
      : `
      `};
`;

const Button: React.FC<Props> = (
  {
    children,
    textColor = 'lTextHigh',
    backgroundColor = 'primary',
    onClick,
    id,
    isMobile,
    availableAfter,
    disabled = false,
    type = 'button'
  },
  props
) => (
  <StyledButton
    {...props}
    backgroundColor={backgroundColor}
    availableAfter={availableAfter}
    onClick={!disabled ? onClick : undefined}
    disabled={disabled}
    id={id}
    type={type}
  >
    <Typography variant={isMobile ? 'body3' : 'button'} color={textColor} overflow="">
      {children}
    </Typography>
  </StyledButton>
);

export default Button;
