/* eslint-disable no-magic-numbers */
import React from 'react';
import useSortingMenuAccessProfilesLockGroups from 'hooks/useSortingMenuAccessProfilesLockGroups/useSortingMenuAccessProfilesLockGroups';
import SortingMenu from 'ui/organisms/SortingMenu/SortingMenu';
import styled from 'styled-components';
import { locksPrioritySorting } from 'hooks/useSorting/useSortingAccessProfiles/LockGroupsAndLocks/useSortingAccessProfilesTableLockGroupsAndLocks';

interface WrapperProps {
  top: number;
  left: number;
}

const Wrapper = styled.div<WrapperProps>`
  position: absolute;
  top: ${({ top }) => top + 16}px;
  left: ${({ left }) => left + 24}px;
`;

const SortingMenuAccessProfilesLockGroups: React.FC = () => {
  const { sortingMenuValue, hideSortingMenu } = useSortingMenuAccessProfilesLockGroups();
  if (sortingMenuValue) {
    const { isMenuOpen, sorting, sortingMenuRef, sortingMenuIconId, handleSortingOnChange, variant } = sortingMenuValue;

    const sortingMenuIcon = document.getElementById(sortingMenuIconId);
    if (sortingMenuIcon) {
      const { top, left } = sortingMenuIcon.getBoundingClientRect();
      return (
        <Wrapper top={top} left={left}>
          <SortingMenu
            ref={sortingMenuRef}
            isOpen={isMenuOpen}
            sortingName="Alphabetic Order"
            additionalSortingName="Priority"
            sortingOptions={[
              {
                isSelected: !!sorting.isAlphabeticAscending,
                onClick: () => {
                  handleSortingOnChange(sorting.priority, true);
                  hideSortingMenu();
                },
                text: 'Ascending',
                id: 'sorting-option-ascending'
              },
              {
                isSelected: !sorting.isAlphabeticAscending,
                onClick: () => {
                  handleSortingOnChange(sorting.priority, false);
                  hideSortingMenu();
                },
                text: 'Descending',
                id: 'sorting-option-descending'
              }
            ]}
            additionalSortingOptions={[
              ...locksPrioritySorting.map((priority) => ({
                isSelected: sorting.priority === priority,
                onClick: () => {
                  handleSortingOnChange(priority, sorting.isAlphabeticAscending);
                  hideSortingMenu();
                },
                text: priority,
                id: `sorting-option-${priority}`
              })),
              variant === 'switch'
                ? {
                    isSelected: sorting.priority === 'Selected',
                    onClick: () => {
                      handleSortingOnChange('Selected', sorting.isAlphabeticAscending);
                      hideSortingMenu();
                    },
                    text: 'Selected',
                    id: 'sorting-option-selected'
                  }
                : undefined
            ]}
          />
        </Wrapper>
      );
    }
  }
  return null;
};

export default SortingMenuAccessProfilesLockGroups;
