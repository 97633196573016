import { AddExternalUserGroupForm } from 'components/Drawer/types';
import React from 'react';
import { UseFormRegister } from 'react-hook-form';
import Switch from 'ui/atoms/Switch/Switch';
import Typography from 'ui/atoms/Typography/Typography';
import Input from 'ui/molecules/Input/Input';
import ComponentWrapper from 'ui/templates/ComponentWrapper/ComponentWrapper';

export interface Props {
  register: UseFormRegister<AddExternalUserGroupForm>;
  nameInputValue: string;
  handleNameInputOnChange: (value: string) => void;
  isNameUnique: boolean;
  switchIsMaximumUserLimitEnable: boolean;
  handleSwitchIsMaximumUserLimitEnableOnChange: () => void;
  inputsMaximumUserLimitValue: number | undefined;
  handlesMaximumUserLimitInputOnChange: (value: number) => void;
  inputOnKeyDown: (event: React.KeyboardEvent<HTMLDivElement>) => void;
}
const AddExternalUserGroup: React.FC<Props> = ({
  register,
  nameInputValue,
  handleNameInputOnChange,
  isNameUnique,
  switchIsMaximumUserLimitEnable,
  handleSwitchIsMaximumUserLimitEnableOnChange,
  inputsMaximumUserLimitValue,
  handlesMaximumUserLimitInputOnChange,
  inputOnKeyDown
}) => (
  <>
    <Input
      {...register('name')}
      onChange={(event) => handleNameInputOnChange(event.target.value)}
      required
      label="Name*"
      value={nameInputValue}
      id="drawer-input-name"
      failed={!isNameUnique}
      failedText="Group name must be unique"
      failedId="drawer-input-name-validation"
      onKeyDown={inputOnKeyDown}
    />
    <ComponentWrapper flexDirection="row" gap="1rem" justifyContent="space-between" alignItems="center" width="100%">
      <Typography variant="body1" color="dTextHigh">
        Enable maximum user limit
      </Typography>
      <Switch
        variant="dark"
        {...register('isMaximumUserLimit')}
        id="drawer-enable-is-maximum-user-limit-span"
        inputId="drawer-enable-is-maximum-user-limit-input"
        checked={switchIsMaximumUserLimitEnable}
        onClick={handleSwitchIsMaximumUserLimitEnableOnChange}
        onKeyDown={inputOnKeyDown}
      />
    </ComponentWrapper>
    <Input
      {...register('maxNumberOfMembers')}
      label="MAXIMUM USERS"
      type="number"
      min={0}
      max={999}
      value={inputsMaximumUserLimitValue}
      disabled={!switchIsMaximumUserLimitEnable}
      id="drawer-input-maximum-user-limit"
      onKeyDown={inputOnKeyDown}
      onChange={
        switchIsMaximumUserLimitEnable
          ? (event) => handlesMaximumUserLimitInputOnChange(Number(event.target.value))
          : undefined
      }
    />
  </>
);

export default AddExternalUserGroup;
