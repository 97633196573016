/* eslint-disable no-magic-numbers */
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import Icon from 'ui/atoms/Icon/Icon';
import TableCell from 'ui/atoms/TableCell/TableCell';
import TableHeader from 'ui/atoms/TableHeader/TableHeader';
import TableLoader from 'ui/molecules/TableLoader/TableLoader';
import Tooltip from 'ui/atoms/Tooltip/Tooltip';
import TableHeaderRow from 'ui/molecules/TableHeaderRow/TableHeaderRow';
import TableRow from 'ui/molecules/TableRow/TableRow';
import Table from 'ui/organisms/Table/Table';
import PageContent from 'ui/templates/PageContent/PageContent';
import Loader from 'ui/atoms/Loader/Loader';
import useAccessEvents from 'hooks/useAccessEvents/useAccessEvents';
import dayjs from 'dayjs';
import useDrawer from 'hooks/useDrawer/useDrawer';
import Drawer from 'components/Drawer/Drawer';
import useAccessEventsFilters from 'hooks/useAccessEventsFilters/useAccessEventsFilters';
import Typography from 'ui/atoms/Typography/Typography';
import Chip from 'ui/atoms/Chip/Chip';
import Modal from 'components/Modal/Modal';
import FilterNoResult from 'ui/molecules/FilterNoResult/FilterNoResult';
import TooltipInfo from 'ui/atoms/TooltipInfo/TooltipInfo';
import ArrayUtil from 'utils/Array/Array.util';
import ComponentWrapper from 'ui/templates/ComponentWrapper/ComponentWrapper';
import { MobileOpCode } from 'graphql/generated/globalTypes';
import useVisitorsGroupsMembers from 'hooks/useVisitorsGroupsMembers/useVisitorsGroupsMembers';
import { GetVisitorGroupsForTenantWithMembers_getVisitorGroupsForTenant_members } from 'graphql/generated/GetVisitorGroupsForTenantWithMembers';
import useDoors from 'hooks/useDoors/useDoors';
import { kebabCase, startCase } from 'lodash';
import { StickyToTop } from 'ui/templates/StickyToTop/StickyToTop';
import useModal from 'hooks/useModal/useModal';
import useExportAccessEvents from 'hooks/useExportAccessEvents/useExportAccessEvents';
import { eventsTabs } from '../eventsTabsConst';

const IconWrapper = styled.div`
  cursor: pointer;
  height: 1.5rem;
`;

const TableWrapper = styled.div`
  z-index: 0;
  height: calc(100vh - 20rem);
  width: calc(100vw - 25rem);
  margin-right: 1rem;
`;

const FilterNoResultWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: calc((100vh - 31.59rem) / 2);
`;

interface SomeProps {
  isNextDataToDownload: boolean;
}

const TableLoaderWrapper = styled.div<SomeProps>`
  ${({ isNextDataToDownload }) =>
    !isNextDataToDownload &&
    `
      display: none;
    `};
`;

const columns = [
  { text: 'Date / time', width: 10 },
  { text: 'User', width: 35 },
  { text: 'Lock', width: 15 },
  { text: 'Action', width: 15 },
  { text: 'Reason', width: 25 }
] as const;

const AccessEvents: React.FC = () => {
  const myRef = useRef<null | HTMLDivElement>(null);
  const [loaderIsVisible, setLoaderIsVisible] = useState<boolean>(false);
  const enqueueSnackbar = useEnqueueSnackbar();
  const handleFetchError = (errorMessage: string) => enqueueSnackbar(errorMessage, { snackbartype: 'error' });
  const {
    accessEventFilters,
    removeDateTimeAppliedFilter,
    removeLockAppliedFilter,
    removeVisitorAppliedFilter,
    removeActionAppliedFilter,
    removeReasonAppliedFilter,
    isFilterApply
  } = useAccessEventsFilters();
  const { visitorsGroupsMembers } = useVisitorsGroupsMembers({
    handleFetchError
  });
  const { doors } = useDoors({
    handleFetchError
  });
  const {
    accessEvents,
    isNextDataToDownload,
    loading: accessEventsLoading
  } = useAccessEvents({
    handleFetchError,
    fetchNextData: loaderIsVisible,
    filter: accessEventFilters.appliedFilters
  });
  const { isDownloadInProgress } = useExportAccessEvents({
    handleFetchError
  });
  const { showDrawer } = useDrawer();
  const { showModal } = useModal();
  const skeletonArray = ArrayUtil.SkeletonArray();

  const filterIconOnClick = () => {
    showDrawer({
      type: 'accessEventsFilters',
      contentValue: { filters: accessEventFilters.appliedFilters }
    });
  };

  const exportIconOnClick = () => {
    if (!isDownloadInProgress) {
      if (isFilterApply())
        showModal({
          type: 'createExportAccessEvents'
        });
      else showModal({ type: 'preparingExportAccessEvents', contentValue: { isFilteredOptionSelected: false } });
    }
  };

  const getMemberNameById = () => {
    const members: Array<GetVisitorGroupsForTenantWithMembers_getVisitorGroupsForTenant_members> = [];
    visitorsGroupsMembers.forEach((group) => members.push(...group.members));
    return members.find(
      (member) =>
        accessEventFilters.appliedFilters.visitorIds && member.id === accessEventFilters.appliedFilters.visitorIds[0]
    )?.name;
  };

  const getLockNameById = () =>
    doors.find(
      (lock) => accessEventFilters.appliedFilters.doorIds && lock.id === accessEventFilters.appliedFilters.doorIds[0]
    )?.name;

  const observer = new IntersectionObserver((entries) => {
    const entry = entries[0];
    setLoaderIsVisible(entry.isIntersecting);
  });

  if (myRef.current) observer.observe(myRef.current);

  return (
    <PageContent title="ATLAS" titleId="location" tabs={eventsTabs}>
      <StickyToTop>
        <ComponentWrapper justifyContent="space-between" width="100%">
          <ComponentWrapper
            justifyContent="flex-start"
            alignItems="flex-start"
            columnGap="0.25rem"
            rowGap="0.25rem"
            flexWrap="wrap"
          >
            {isFilterApply() && (
              <ComponentWrapper justifyContent="center">
                <ComponentWrapper margin="0 0.25rem 0 0">
                  <Typography overflow="">Filter:</Typography>
                </ComponentWrapper>
                {accessEventFilters.appliedFilters.dateRange && (
                  <Chip
                    key="access-events-date-time-chip"
                    title="Date/Time:"
                    value={`${dayjs(accessEventFilters.appliedFilters.dateRange.rangeStart).format(
                      'D MMM HH:mm'
                    )} - ${dayjs(accessEventFilters.appliedFilters.dateRange.rangeEnd).format('D MMM HH:mm')}`}
                    id="access-events-date-time-chip"
                    deleteOnClick={() => removeDateTimeAppliedFilter()}
                  />
                )}
                {accessEventFilters.appliedFilters.visitorIds && (
                  <Chip
                    key="access-events-user-chip"
                    title="User:"
                    value={`${getMemberNameById()}`}
                    id="access-events-user-chip"
                    deleteOnClick={() => removeVisitorAppliedFilter()}
                  />
                )}
                {accessEventFilters.appliedFilters.doorIds && (
                  <Chip
                    key="access-events-door-chip"
                    title="Lock:"
                    value={`${getLockNameById()}`}
                    id="access-events-door-chip"
                    deleteOnClick={() => removeLockAppliedFilter()}
                  />
                )}
                {accessEventFilters.appliedFilters.actions && (
                  <Chip
                    key="access-events-actions-chip"
                    title="Action:"
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                    value={startCase(`${accessEventFilters.appliedFilters.actions}`.replaceAll('_', ' ').toLowerCase())}
                    id="access-events-actions-chip"
                    deleteOnClick={() => removeActionAppliedFilter()}
                  />
                )}
                {accessEventFilters.appliedFilters.reason && (
                  <Chip
                    key="access-events-reason-chip"
                    title="Reason:"
                    value={`${accessEventFilters.appliedFilters.reason}`}
                    id="access-events-reason-chip"
                    deleteOnClick={() => removeReasonAppliedFilter()}
                  />
                )}
              </ComponentWrapper>
            )}
          </ComponentWrapper>
          <ComponentWrapper alignItems="center" justifyContent="center" gap="0.5rem">
            <IconWrapper>
              <Icon
                name="Filter"
                color={isFilterApply() ? 'primary' : 'lTextMedium'}
                id="access-events-filterIcon-icon"
                onClick={filterIconOnClick}
                viewBox="0 0 24 24"
              />
            </IconWrapper>

            {isDownloadInProgress ? (
              <Loader />
            ) : (
              <Tooltip
                elementOnHover={
                  <ComponentWrapper width="7rem" padding="0.5rem">
                    <Typography variant="body3">Export to file</Typography>
                  </ComponentWrapper>
                }
                id="access-events-export-tooltip"
              >
                <IconWrapper>
                  <Icon
                    name="Export"
                    color="lTextMedium"
                    id="access-events-exportIcon"
                    onClick={exportIconOnClick}
                    viewBox="0 0 24 24"
                  />
                </IconWrapper>
              </Tooltip>
            )}
            <Tooltip
              elementOnHover={<TooltipInfo firstLineText="The retention time is 30 days" />}
              id="access-events-tooltip"
            >
              <IconWrapper>
                <Icon name="Info" id="access-events-i-icon" />
              </IconWrapper>
            </Tooltip>
          </ComponentWrapper>
        </ComponentWrapper>
      </StickyToTop>
      <TableWrapper>
        <Table
          header={
            <TableHeaderRow>
              {columns.map((column) => (
                <TableHeader
                  headerText={column.text}
                  flex={`1 0 ${column.width}%`}
                  id={kebabCase(`access-events-${column.text}`)}
                />
              ))}
            </TableHeaderRow>
          }
        >
          {accessEvents.length === 0 && isFilterApply() && (
            <FilterNoResultWrapper>
              <FilterNoResult />
            </FilterNoResultWrapper>
          )}
          {accessEvents.length === 0 &&
            accessEventsLoading &&
            skeletonArray?.map((_, index) => (
              <TableRow key={`skeletonTableRow-${_.id}`} id={`access-events-row-skeleton-${index}`}>
                <TableCell isLoading firstLineText="" flex={`1 0 ${columns[0].width}%`} />
                <TableCell isLoading firstLineText="" flex={`1 0 ${columns[1].width}%`} />
                <TableCell isLoading firstLineText="" flex={`1 0 ${columns[2].width}%`} />
                <TableCell isLoading firstLineText="" flex={`1 0 ${columns[3].width}%`} />
                <TableCell isLoading firstLineText="" flex={`1 0 ${columns[4].width}%`} />
              </TableRow>
            ))}
          {accessEvents.length > 0 &&
            accessEvents?.map((accessEvent, index) => (
              <TableRow key={`accessEvent-${accessEvent.id}`} hoverEffect id={`access-events-row-${index}`}>
                <TableCell
                  firstLineText={dayjs(accessEvent.createdAt).format('HH:mm:ss')}
                  firstLineId={`access-events-time-${index}`}
                  secondLineText={dayjs(accessEvent.createdAt).format('D MMM YYYY')}
                  secondLineId={`access-events-date-${index}`}
                  flex={`1 0 ${columns[0].width}%`}
                />
                <TableCell
                  secondLineText={accessEvent.visitor.email}
                  secondLineId={`access-events-user-email-${index}`}
                  firstLineText={accessEvent.visitor.name}
                  firstLineId={`access-events-user-name-${index}`}
                  flex={`1 0 ${columns[1].width}%`}
                />
                <TableCell
                  firstLineText={accessEvent.door.name}
                  firstLineId={`access-events-door-name-${index}`}
                  flex={`1 0 ${columns[2].width}%`}
                />
                <TableCell
                  firstLineText={
                    accessEvent.action === MobileOpCode.ACCESS_GRANTED ? 'Access granted' : 'Access denied'
                  }
                  firstLineId={`access-events-action-${index}`}
                  firstLineColor={accessEvent.action === MobileOpCode.ACCESS_GRANTED ? 'success' : 'error'}
                  flex={`1 0 ${columns[3].width}%`}
                />
                <TableCell
                  firstLineText={accessEvent.reason || '-'}
                  firstLineId={`access-events-reason-${index}`}
                  flex={`1 0 ${columns[4].width}%`}
                />
              </TableRow>
            ))}
          <TableLoaderWrapper isNextDataToDownload={isNextDataToDownload}>
            <TableRow key="access-events-get-more-data-loader" hoverEffect>
              <TableLoader ref={myRef}>
                <Loader id="access-events-get-more-data-loader" />
              </TableLoader>
            </TableRow>
          </TableLoaderWrapper>
        </Table>
      </TableWrapper>
      <Drawer />
      <Modal />
    </PageContent>
  );
};

export default AccessEvents;
