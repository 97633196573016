import React from 'react';
import styled from 'styled-components';
import { Colors } from 'theme/theme';
import Icon from 'ui/atoms/Icon/Icon';
import Tooltip from 'ui/atoms/Tooltip/Tooltip';
import Typography from 'ui/atoms/Typography/Typography';
import ComponentWrapper from 'ui/templates/ComponentWrapper/ComponentWrapper';
import { getLockGroupsIconProperties } from 'utils/LockGroupsIcon/LockGroupsIcon.util';

export interface Props {
  lockGroupName: string;
  onClick?: () => void;
  onDeleteClickIcon?: () => void;
  numberOfSelectedDoors?: number;
  isSelected?: boolean;
  displayIcon?: boolean;
  id: string;
  deleteIconId?: string;
}

const handleBordererIconColor = (isSelected?: boolean, numberOfSelectedDoors?: number): keyof Colors => {
  if (isSelected) return 'primary';
  if (numberOfSelectedDoors === 0) return 'warning';
  return 'lTextMedium';
};

const IconWrapper = styled.div`
  height: 1.5rem;
  cursor: pointer;
`;

const EmptySpace = styled.div`
  width: 1.5rem;
`;

const StyledWrapper = styled.div<Pick<Props, 'isSelected' | 'numberOfSelectedDoors'>>`
  border: 1px solid
    ${({ theme, isSelected, numberOfSelectedDoors }) =>
      theme.colors[handleBordererIconColor(isSelected, numberOfSelectedDoors)]};
  border-radius: 0.25rem;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  width: 99%;

  & ${IconWrapper} {
    svg {
      display: none;
    }
  }

  & ${EmptySpace} {
    display: flex;
  }

  &:hover {
    ${IconWrapper} {
      svg {
        display: flex;
        width: 1.5rem;
        justify-content: center;
        align-items: center;
      }
    }

    ${EmptySpace} {
      display: none;
    }
  }
`;

const StyledGroupName = styled(Typography)`
  max-width: 15rem;
  margin-right: 0.5rem;
`;

const StyledTooltipText = styled(Typography)`
  padding: 0.5rem;
`;

const LockGroup: React.FC<Props> = ({
  lockGroupName,
  numberOfSelectedDoors,
  isSelected = false,
  displayIcon = true,
  id,
  deleteIconId,
  onClick,
  onDeleteClickIcon
}) => {
  const handleWrapperOnClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    if (onClick !== undefined) onClick();
  };

  const handleIconOnClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    if (onDeleteClickIcon !== undefined) onDeleteClickIcon();
  };
  return (
    <StyledWrapper
      isSelected={isSelected}
      numberOfSelectedDoors={numberOfSelectedDoors}
      onClick={(event) => handleWrapperOnClick(event)}
    >
      <ComponentWrapper flexDirection="row" id={id} alignItems="center" margin="0.75rem 0.5rem" width="21.5">
        {displayIcon && <Icon {...getLockGroupsIconProperties(isSelected, Boolean(numberOfSelectedDoors !== 0))} />}
        <ComponentWrapper width="17rem" height="1.5rem" alignItems="center" margin="0 0.5rem">
          <Tooltip
            right="0%"
            top="100%"
            elementOnHover={<StyledTooltipText variant="body4">{lockGroupName}</StyledTooltipText>}
            id="lock-group-name-tooltip"
          >
            <ComponentWrapper>
              <StyledGroupName variant="body3" color="dTextHigh" whiteSpace="nowrap">
                {lockGroupName}
              </StyledGroupName>
              {numberOfSelectedDoors !== undefined && (
                <Typography
                  variant="body3"
                  color="dTextHigh"
                  whiteSpace="nowrap"
                >{`(${numberOfSelectedDoors})`}</Typography>
              )}
            </ComponentWrapper>
          </Tooltip>
        </ComponentWrapper>
        {displayIcon && (
          <IconWrapper onClick={(event) => handleIconOnClick(event)} id={deleteIconId}>
            <Tooltip
              elementOnHover={<StyledTooltipText variant="body4">Delete group</StyledTooltipText>}
              id="lock-group-name-tooltip"
            >
              <Icon color="error" name="DeleteBin" width={24} height={24} viewBox="0 0 16 16" />
            </Tooltip>
          </IconWrapper>
        )}
        <EmptySpace />
      </ComponentWrapper>
    </StyledWrapper>
  );
};

export default LockGroup;
