import dayjs from 'dayjs';
import { ExpirationDateInputValidation, NameInputValidation } from '../validationTexts';

export const handleNameInputValidation = (
  setNameInputValidation: (value: React.SetStateAction<NameInputValidation>) => void,
  nameInputValue?: string
) => {
  if (nameInputValue === '') setNameInputValidation('Name cannot be empty');
  else setNameInputValidation(undefined);
};

export const handleExpirationDateInputValidation = (
  setExpirationDateInputValidation: (value: React.SetStateAction<ExpirationDateInputValidation>) => void,
  isExpirationDateEnabled?: boolean,
  expirationDateTo?: string
) => {
  if (isExpirationDateEnabled && dayjs(expirationDateTo).isBefore(dayjs()))
    setExpirationDateInputValidation('Expiration date cannot be in the past');
  else setExpirationDateInputValidation(undefined);
};

export const handleExpirationDateInputValue = (
  setExpirationDateInputValue: (value: React.SetStateAction<string | undefined>) => void,
  expirationDateTo?: string
) => {
  if (expirationDateTo) setExpirationDateInputValue(dayjs(expirationDateTo).format('DD-MM-YYYY HH:mm'));
  else setExpirationDateInputValue('-');
};

export const handleSwitchIsExpirationDateOnChange = (
  setIsExpirationDateEnabled: (value: React.SetStateAction<boolean | undefined>) => void,
  setExpirationDateTo: (value: React.SetStateAction<string | undefined>) => void,
  setExpirationDateInputValue: (value: React.SetStateAction<string | undefined>) => void,
  isExpirationDateEnabled?: boolean,
  expirationDateTo?: string
) => {
  setIsExpirationDateEnabled(!isExpirationDateEnabled);
  if (expirationDateTo === undefined) {
    setExpirationDateTo(dayjs().add(1, 'day').toISOString());
  }
  setExpirationDateInputValue(!isExpirationDateEnabled ? dayjs(expirationDateTo).format('DD-MM-YYYY HH:mm') : '-');
};
