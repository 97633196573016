import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import SideNavUI from 'ui/organisms/SideNav/SideNav';
import { useMsal } from '@azure/msal-react';
import { useReactiveVar } from '@apollo/client';
import { authVar } from 'state/vars';
import usePagesAvailability from 'hooks/usePagesAvailability/usePagesAvailability';

const SideNav: React.FC = () => {
  const location = useLocation();
  const { instance, accounts } = useMsal();
  const { user } = useReactiveVar(authVar);
  const navigate = useNavigate();
  const { getSideNavItems } = usePagesAvailability();

  const handleLogout = async () => {
    await instance.logout({ account: accounts[0], postLogoutRedirectUri: process.env.REACT_APP_SSO_REDIRECT_URI });
  };

  const navigateToMyProfile = () => {
    navigate('./my-profile/access-app');
  };

  const getItems = (currentLocation: string) =>
    getSideNavItems().map((item) => ({
      ...item,
      isSelected: `/${currentLocation.split('/')[1]}`.includes(item.location)
    }));

  return (
    <SideNavUI
      items={getItems(location.pathname)}
      profile={{ name: user?.name || '' }}
      isAdmin={user?.isAdmin!}
      singOut={handleLogout}
      profileOnClick={navigateToMyProfile}
    />
  );
};

export default SideNav;
