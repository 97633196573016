/* eslint-disable import/prefer-default-export */
import { TabsType } from 'components/Tabs/Tabs';

export const accessTabs: TabsType[] = [
  {
    text: 'Access profiles',
    location: '/access/access-profiles'
  },
  {
    text: 'Schedules',
    location: '/access/schedules'
  },
  {
    text: 'Access settings',
    location: '/access/access-settings'
  }
];
