/* eslint-disable no-magic-numbers */
import React, { RefObject, useEffect, useLayoutEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import * as CSS from 'csstype';
import { Colors } from 'theme/theme';

export type TypographyVariant =
  | 'loginTitle'
  | 'title1'
  | 'title2'
  | 'title3'
  | 'title4'
  | 'body'
  | 'body1'
  | 'body2'
  | 'body3'
  | 'body4'
  | 'button'
  | 'super'
  | 'clickable'
  | 'label'
  | 'helper';

export interface Props {
  variant?: TypographyVariant;
  color?: keyof Colors;
  opacity?: string;
  whiteSpace?: string;
  textOverflow?: string;
  overflow?: string;
  id?: string;
}

interface TypographyBaseProps {
  isTruncated?: boolean;
  top?: number;
  left?: number;
}

export const calculateDetails = (size: number, height: number, fontWeight: CSS.Property.FontWeight = 'normal') => css`
  font-size: ${size}rem;
  line-height: ${height}rem;
  font-weight: ${fontWeight};
`;

export const handleVariantDetails = (variant: TypographyVariant) => {
  switch (variant) {
    case 'loginTitle':
      return calculateDetails(4, 6.75, 500);
    case 'title1':
      return calculateDetails(2, 3, 500);
    case 'title2':
      return calculateDetails(1.5, 2.25, 500);
    case 'title3':
      return calculateDetails(1.5, 2.25, 300);
    case 'title4':
      return calculateDetails(1.125, 1.6875, 500);
    case 'body1':
      return calculateDetails(1.125, 1.6875);
    case 'body2':
      return calculateDetails(1, 1.5);
    case 'body3':
      return calculateDetails(0.875, 1.3125);
    case 'body4':
      return calculateDetails(0.75, 1.125);
    case 'button':
      return calculateDetails(1, 1.5, 600);
    case 'super':
      return calculateDetails(0.625, 0.9375);
    case 'clickable':
      return calculateDetails(0.875, 1.3125);
    case 'label':
    case 'helper':
      return calculateDetails(0.5, 0.75, 600);
    case 'body':
    default:
      return calculateDetails(1.125, 1.6875);
  }
};

const TypographyBase = css<Props & TypographyBaseProps>`
  font-style: normal;
  margin: 0;
  text-overflow: ${(props) => props.textOverflow};
  overflow: ${(props) => props.overflow};
  color: ${(props) => (props.color ? props.theme.colors[props.color] : undefined)};
  opacity: ${(props) => props.opacity};
  white-space: ${(props) => props.whiteSpace};
  ${(props) => (props.variant ? handleVariantDetails(props.variant) : undefined)}
  position: relative;
  display: inline-block;

  ${({ isTruncated, theme, top, left }) =>
    isTruncated
      ? `
      &::before {
        content: attr(data-tooltip);
        position: fixed;
        top: ${Math.round(top!)}px;
        left: ${Math.round(left!)}px;
        padding: 0.5rem;
        margin-top: 1.5rem;
        background-color: ${theme.colors.b3};
        color: ${theme.colors.lTextHigh};
        display: none;
        opacity: 0;
        transition: opacity 1 ease-in-out;
      }

  &:hover::before {
    display: flex;
    opacity: 1;
  }`
      : `
      `};
`;

const StyledLoginTitle = styled.h1`
  ${TypographyBase};
  text-align: center;
`;

const StyledHeader = styled.h1`
  ${TypographyBase};
`;

const StyledSubHeader = styled.h2`
  ${TypographyBase};
`;

const StyledParagraph = styled.p`
  ${TypographyBase};
`;

const StyledBodyVariant = styled.p`
  ${TypographyBase};
  letter-spacing: 0.05em;
`;

const StyledButtonText = styled.span`
  ${TypographyBase};
`;

const StyledSuper = styled.sup`
  ${TypographyBase};
`;

const StyledClickable = styled.div`
  ${TypographyBase};
  letter-spacing: 0.05em;
  text-decoration-line: underline;
  cursor: pointer;
`;

const StyledLabel = styled.label`
  ${TypographyBase};
  letter-spacing: 0.15em;
  text-transform: uppercase;
`;

const StyledHelper = styled.span`
  ${TypographyBase};
  letter-spacing: 0.15em;
`;

const Typography: React.FC<Props> = ({
  variant = 'body',
  color = 'lTextHigh',
  opacity = '1',
  whiteSpace = 'normal',
  textOverflow = 'ellipsis',
  overflow = 'hidden',
  id = '',
  children,
  ...rest
}) => {
  const props = {
    ...rest,
    variant,
    color,
    opacity,
    whiteSpace,
    textOverflow,
    overflow,
    id
  };

  const textRef = useRef<HTMLHeadingElement | HTMLLabelElement | HTMLDivElement>(null);
  const [isHovered, setIsHovered] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);
  const [position, setPosition] = useState({ top: 0, left: 0 });

  useEffect(() => {
    if (textRef.current) {
      const { scrollWidth, clientWidth } = textRef.current;
      setIsTruncated(scrollWidth > clientWidth);
    }
  }, [children]);

  const updatePosition = () => {
    if (textRef.current) {
      const { top, left } = textRef.current.getBoundingClientRect();
      const { scrollWidth, clientWidth } = textRef.current;
      setIsTruncated(scrollWidth > clientWidth);
      setPosition({ top, left });
    }
  };

  useLayoutEffect(() => {
    updatePosition();
  }, [isHovered]);

  useEffect(() => {
    updatePosition();
    if (textRef.current) {
      let elementWithScroll = textRef.current.parentNode as HTMLElement | null;
      while (elementWithScroll && !(elementWithScroll.scrollHeight > elementWithScroll.clientHeight)) {
        elementWithScroll = elementWithScroll.parentNode as HTMLElement | null;
      }
      if (elementWithScroll !== null) {
        elementWithScroll.addEventListener('scroll', updatePosition);
        window.addEventListener('resize', updatePosition);

        return () => {
          if (elementWithScroll !== null) {
            elementWithScroll.removeEventListener('scroll', updatePosition);
            window.removeEventListener('resize', updatePosition);
          }
        };
      }
    }
    return undefined;
  }, [textRef]);

  interface TypographyCommonProps {
    isTruncated: boolean;
    top: number;
    left: number;
    onMouseOver: () => void;
    onMouseOut: () => void;
    'data-tooltip': string;
  }

  const commonTypographyProps: TypographyCommonProps = {
    isTruncated,
    top: position.top,
    left: position.left,
    onMouseOver: () => setIsHovered(true),
    onMouseOut: () => setIsHovered(false),
    'data-tooltip': `${children}`
  };

  return (
    <>
      {
        {
          loginTitle: (
            <StyledLoginTitle ref={textRef as RefObject<HTMLHeadingElement>} {...commonTypographyProps} {...props}>
              {children}
            </StyledLoginTitle>
          ),
          title1: (
            <StyledHeader ref={textRef as RefObject<HTMLHeadingElement>} {...commonTypographyProps} {...props}>
              {children}
            </StyledHeader>
          ),
          title2: (
            <StyledHeader ref={textRef as RefObject<HTMLHeadingElement>} {...commonTypographyProps} {...props}>
              {children}
            </StyledHeader>
          ),
          title3: (
            <StyledHeader ref={textRef as RefObject<HTMLHeadingElement>} {...commonTypographyProps} {...props}>
              {children}
            </StyledHeader>
          ),
          title4: (
            <StyledSubHeader ref={textRef as RefObject<HTMLHeadingElement>} {...commonTypographyProps} {...props}>
              {children}
            </StyledSubHeader>
          ),
          body: (
            <StyledParagraph ref={textRef as RefObject<HTMLParagraphElement>} {...commonTypographyProps} {...props}>
              {children}
            </StyledParagraph>
          ),
          body1: (
            <StyledBodyVariant ref={textRef as RefObject<HTMLParagraphElement>} {...commonTypographyProps} {...props}>
              {children}
            </StyledBodyVariant>
          ),
          body2: (
            <StyledBodyVariant ref={textRef as RefObject<HTMLParagraphElement>} {...commonTypographyProps} {...props}>
              {children}
            </StyledBodyVariant>
          ),
          body3: (
            <StyledBodyVariant ref={textRef as RefObject<HTMLParagraphElement>} {...commonTypographyProps} {...props}>
              {children}
            </StyledBodyVariant>
          ),
          body4: (
            <StyledBodyVariant ref={textRef as RefObject<HTMLParagraphElement>} {...commonTypographyProps} {...props}>
              {children}
            </StyledBodyVariant>
          ),
          button: (
            <StyledButtonText ref={textRef} {...commonTypographyProps} {...props}>
              {children}
            </StyledButtonText>
          ),
          super: (
            <StyledSuper ref={textRef} {...commonTypographyProps} {...props}>
              {children}
            </StyledSuper>
          ),
          clickable: (
            <StyledClickable
              ref={textRef as RefObject<HTMLDivElement>}
              {...commonTypographyProps}
              {...props}
              role="button"
              tabIndex={0}
            >
              {children}
            </StyledClickable>
          ),
          label: (
            <StyledLabel ref={textRef as RefObject<HTMLLabelElement>} {...commonTypographyProps} {...props}>
              {children}
            </StyledLabel>
          ),
          helper: (
            <StyledHelper ref={textRef} {...commonTypographyProps} {...props}>
              {children}
            </StyledHelper>
          )
        }[variant]
      }
    </>
  );
};

export default Typography;
