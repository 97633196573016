import { AddInstallerForm } from 'components/Drawer/types';
import { EmailInputValidation, NameInputValidation } from 'components/Drawer/variants/validationTexts';
import React from 'react';
import { UseFormRegister } from 'react-hook-form';
import Input from 'ui/molecules/Input/Input';

export interface Props {
  register: UseFormRegister<AddInstallerForm>;
  nameInputValue: string;
  handleNameInputOnChange: (value: string) => void;
  nameInputValidation: NameInputValidation;
  emailInputValue: string;
  handleEmailInputValueChange: (value: string) => void;
  emailInputValidation: EmailInputValidation;
  showValidation: boolean;
  inputOnKeyDown: (event: React.KeyboardEvent<HTMLDivElement>) => void;
}
const AddInstaller: React.FC<Props> = ({
  register,
  nameInputValue,
  handleNameInputOnChange,
  nameInputValidation,
  emailInputValue,
  handleEmailInputValueChange,
  emailInputValidation,
  showValidation,
  inputOnKeyDown
}) => (
  <>
    <Input
      {...register('name')}
      onChange={(event) => handleNameInputOnChange(event.target.value)}
      customRequired
      label="Name*"
      failed={showValidation && nameInputValidation !== undefined}
      value={nameInputValue}
      failedText={nameInputValidation}
      failedId="drawer-input-name-validation"
      id="drawer-input-name"
      onKeyDown={inputOnKeyDown}
    />
    <Input
      {...register('email')}
      customRequired
      onChange={(event) => handleEmailInputValueChange(event.target.value)}
      label="E-Mail*"
      failed={showValidation && emailInputValidation !== undefined}
      value={emailInputValue}
      failedText={emailInputValidation}
      failedId="drawer-input-email-validation"
      id="drawer-input-email"
      onKeyDown={inputOnKeyDown}
    />
  </>
);

export default AddInstaller;
