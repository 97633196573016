import React from 'react';
import Modal from 'ui/atoms/Modal/Modal';
import Button from 'ui/atoms/Button/Button';
import Typography from 'ui/atoms/Typography/Typography';
import Icon from 'ui/atoms/Icon/Icon';
import useModal from 'hooks/useModal/useModal';
import ComponentWrapper from 'ui/templates/ComponentWrapper/ComponentWrapper';

const DisconnectMobileDevice: React.FC = () => {
  const { showModal, hideModal } = useModal();

  const handleUnregisterMobileDevice = () => {
    hideModal();
    showModal({ type: 'removeOrganizationFromMobileApp' });
  };

  return (
    <Modal>
      <ComponentWrapper
        flexDirection="column"
        width="21rem"
        gap="1rem"
        margin="1rem 0"
        justifyContent="center"
        alignItems="center"
      >
        <Icon name="DeleteBin" color="dTextHigh" width={40} height={40} viewBox="0 0 24 24" id="delete-bin-icon" />
        <ComponentWrapper flexDirection="column" justifyContent="center" alignItems="center">
          <ComponentWrapper flexDirection="column" justifyContent="center" alignItems="center">
            <Typography variant="title2" color="dTextHigh">
              Are you sure that you want
            </Typography>
            <Typography variant="title2" color="dTextHigh">
              to disconnect this device?
            </Typography>
          </ComponentWrapper>
          <ComponentWrapper flexDirection="column" justifyContent="center" alignItems="center">
            <Typography variant="body3" color="dTextHigh">
              Your access profile will be unlinked from
            </Typography>
            <Typography variant="body3" color="dTextHigh">
              your mobile device.
            </Typography>
          </ComponentWrapper>
        </ComponentWrapper>
        <ComponentWrapper width="100%">
          <Button backgroundColor="transparent" textColor="b1" onClick={hideModal} id="cancel-button-modal">
            No, Thank you
          </Button>
          <Button onClick={handleUnregisterMobileDevice} id="ok-button-modal">
            Yes, i&apos;m sure
          </Button>
        </ComponentWrapper>
      </ComponentWrapper>
    </Modal>
  );
};

export default DisconnectMobileDevice;
