import { AddInstallerForm } from 'components/Drawer/types';
import useInstallers from 'hooks/useInstallers/useInstallers';
import useDrawer from 'hooks/useDrawer/useDrawer';
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import DrawerTemplate from 'ui/organisms/Drawer/DrawerTemplate';
import AddInstaller from 'ui/organisms/Drawer/variants/AddInstaller';
import { formKeyDownHandler } from 'utils/InputOnKeyDown/formKeyDownHandler.util';
import { EmailInputValidation, NameInputValidation } from '../validationTexts';

const AddInstallerVariant: React.FC = () => {
  const { register, handleSubmit, setValue } = useForm<AddInstallerForm>();
  const enqueueSnackbar = useEnqueueSnackbar();
  const handleFetchError = (errorMessage: string) => enqueueSnackbar(errorMessage, { snackbartype: 'error' });
  const { installersGroups, setInstallerMember, setInstallerMemberLoading } = useInstallers({
    handleFetchError
  });
  const { hideDrawer } = useDrawer();
  const [nameInputValue, setNameInputValue] = useState('');
  const [nameInputValidation, setNameInputValidation] = useState<NameInputValidation>(undefined);
  const [emailInputValue, setEmailInputValue] = useState('');
  const [emailInputValidation, setEmailInputValidation] = useState<EmailInputValidation>(undefined);
  const [showValidation, setShowValidation] = useState(false);

  const onSubmit = async () => {
    setShowValidation(true);
    if (nameInputValidation === undefined && emailInputValidation === undefined && !setInstallerMemberLoading) {
      try {
        const response = await setInstallerMember(emailInputValue, nameInputValue);
        if (response?.setInstaller.name !== nameInputValue && !response?.setInstaller.isEditable)
          enqueueSnackbar(
            'The user you have added into ATLAS is already existing, therefor we reuse the already known user name.',
            { snackbartype: 'success' }
          );
      } finally {
        hideDrawer();
      }
    }
  };

  useEffect(() => {
    setValue('name', nameInputValue);
    setValue('email', emailInputValue);
  }, [emailInputValue, nameInputValue, setValue]);

  useEffect(() => {
    if (nameInputValue === '') setNameInputValidation('Name cannot be empty');
    else setNameInputValidation(undefined);
  }, [nameInputValue]);

  useEffect(() => {
    if (emailInputValue === '') setEmailInputValidation('Please enter an email address');
    else if (
      !emailInputValue
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    )
      setEmailInputValidation('This is not a correct email address');
    else if (installersGroups.some(({ members }) => members.some((member) => member.email === emailInputValue)))
      setEmailInputValidation('Email has already been added');
    else setEmailInputValidation(undefined);
  }, [installersGroups, emailInputValue]);

  return (
    <DrawerTemplate
      title="Add installer"
      confirmButtonText="Add"
      id="drawer-add-installer-group"
      onSubmit={handleSubmit(onSubmit)}
      disableConfirmButton={showValidation && (nameInputValidation !== undefined || emailInputValidation !== undefined)}
    >
      <AddInstaller
        register={register}
        nameInputValue={nameInputValue}
        handleNameInputOnChange={setNameInputValue}
        nameInputValidation={nameInputValidation}
        emailInputValue={emailInputValue}
        handleEmailInputValueChange={setEmailInputValue}
        emailInputValidation={emailInputValidation}
        showValidation={showValidation}
        inputOnKeyDown={(event) => formKeyDownHandler(event, onSubmit)}
      />
    </DrawerTemplate>
  );
};
export default AddInstallerVariant;
