import SideNav from 'components/SideNav/SideNav';
import React from 'react';
import styled from 'styled-components';

interface Props {
  showSideNav: boolean;
}

const RenderWithLayout = styled.div`
  display: flex;
  overflow: hidden;
  max-width: 100vw;
  max-height: 100vh;

  ${({ theme: { isMobile } }) => (isMobile ? 'margin-left: 0;' : '')};
`;

const SideNavWrapper = styled.div`
  display: flex;
  flex: 0 0 14rem;
  top: 0;
  left: 0;
  height: 100vh;
`;

const ChildrenWrapper = styled.div`
  max-height: 100vh;
  min-height: 100vh;
  ${({ theme: { isMobile } }) => (isMobile ? '' : 'flex: 1 1 auto;')};
  ${({ theme: { isMobileLandscape } }) => (isMobileLandscape ? 'overflow: auto; height: 100vh;' : '')}
`;

const Layout: React.FC<Props> = ({ children, showSideNav }) => (
  <RenderWithLayout>
    {showSideNav && (
      <SideNavWrapper id="sidebar">
        <SideNav />
      </SideNavWrapper>
    )}
    <ChildrenWrapper>{children}</ChildrenWrapper>
  </RenderWithLayout>
);

export default Layout;
