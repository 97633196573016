import React from 'react';
import Icon from 'ui/atoms/Icon/Icon';
import Separator from 'ui/atoms/Separator/Separator';
import Typography from 'ui/atoms/Typography/Typography';
import ComponentWrapper from 'ui/templates/ComponentWrapper/ComponentWrapper';

const AccessProfilesTooltipInfo: React.FC = () => (
  <ComponentWrapper flexDirection="column" width="33.875rem" padding="0.5rem 1rem" gap="1rem">
    <Typography variant="title4" color="lTextHigh">
      <b>Information</b>
    </Typography>
    <ComponentWrapper flexDirection="row" gap="0.5rem" justifyContent="left" alignItems="center">
      <Icon name="MicrosoftOutlook" color="lTextHigh" />
      <Typography variant="body4" color="lTextHigh">
        <b>Doors with an Outlook room</b>
      </Typography>
    </ComponentWrapper>
    <ComponentWrapper flexDirection="column" gap="2rem">
      <ComponentWrapper flexDirection="column">
        <Typography variant="body4" color="lTextHigh">
          Locks with this icon are assigned to a room that is connected with Microsoft
        </Typography>
        <Typography variant="body4" color="lTextHigh">
          Outlook. These rooms can be reserved and accessed by any user, regardless
        </Typography>
        <Typography variant="body4" color="lTextHigh">
          of access profile.
        </Typography>
      </ComponentWrapper>
      <ComponentWrapper flexDirection="column">
        <Typography variant="body4" color="lTextHigh">
          Users assigned to these locks can access the rooms without needing an Outlook reservation.
        </Typography>
      </ComponentWrapper>
    </ComponentWrapper>
    <Separator color="b4" />
    <ComponentWrapper flexDirection="row" gap="0.5rem" justifyContent="left" alignItems="center">
      <Icon name="TodayOutlined" color="lTextHigh" />
      <Typography variant="body4" color="lTextHigh">
        <b>ATLAS Reservation required</b>
      </Typography>
    </ComponentWrapper>
    <ComponentWrapper flexDirection="column">
      <ComponentWrapper flexDirection="column">
        <Typography variant="body4" color="lTextHigh">
          User groups with this icon are required to make a reservation using the ATLAS
        </Typography>
        <Typography variant="body4" color="lTextHigh">
          portal.
        </Typography>
      </ComponentWrapper>
    </ComponentWrapper>
  </ComponentWrapper>
);

export default AccessProfilesTooltipInfo;
