import { Role } from 'graphql/generated/globalTypes';
import { OptionProps } from 'ui/molecules/Select/Select';

export const roleSelectOptions: Array<OptionProps<null | Role>> = [
  {
    value: Role.EXTERNAL_GROUP_USER,
    label: 'User'
  },
  {
    value: Role.EXTERNAL_GROUP_USER_MANAGER,
    label: 'Owner'
  }
];

export const getRoleOptionProp = (role: Role) => {
  const roleOption = roleSelectOptions.find((item) => item.value === role);
  if (roleOption)
    return {
      value: role,
      label: roleSelectOptions.find((item) => item.value === role)?.label
    } as unknown as OptionProps<null | Role>;
  return undefined;
};

export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
