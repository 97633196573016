import React, { useEffect, useRef, useState } from 'react';
import TableCell from 'ui/atoms/TableCell/TableCell';
import TableHeader from 'ui/atoms/TableHeader/TableHeader';
import TableHeaderRow from 'ui/molecules/TableHeaderRow/TableHeaderRow';
import TableRow from 'ui/molecules/TableRow/TableRow';
import Table from 'ui/organisms/Table/Table';
import { GetLocationsWithDoorsForTenant_getLocationsForTenant } from 'graphql/generated/GetLocationsWithDoorsForTenant';
import ArrayUtil from 'utils/Array/Array.util';
import { Props as IconProps } from 'ui/atoms/Icon/Icon';
import { getLockGroupsIconProperties } from 'utils/LockGroupsIcon/LockGroupsIcon.util';
import useSortingAccessProfilesTableLockGroupsAndLocks from 'hooks/useSorting/useSortingAccessProfiles/LockGroupsAndLocks/useSortingAccessProfilesTableLockGroupsAndLocks';
import { DoorsState } from 'state/types';
import useSortingMenuAccessProfilesLockGroups from 'hooks/useSortingMenuAccessProfilesLockGroups/useSortingMenuAccessProfilesLockGroups';
import {
  ARROW_DOWN_HEIGHT,
  ARROW_DOWN_WIDTH,
  ARROW_RIGHT_HEIGHT,
  ARROW_RIGHT_WIDTH,
  SecondLineText,
  Variant
} from '../shared';

export interface Props {
  id: string;
  loading: boolean;
  disabled: boolean;
  isCollapsed: boolean;
  handleCollapse: () => void;
  variant: Variant;
  lockGroups: GetLocationsWithDoorsForTenant_getLocationsForTenant[];
  filteredLockGroups: GetLocationsWithDoorsForTenant_getLocationsForTenant[];
  selectedIds: string[];
  disabledIds: string[];
  additionalTexts: SecondLineText[];
  warningTexts: SecondLineText[];
  handleClickTableRow: (lockGroup: GetLocationsWithDoorsForTenant_getLocationsForTenant) => void;
  doors: DoorsState;
  selectedDoorsIds: string[];
}

const AccessProfilesLockGroups: React.FC<Props> = ({
  id,
  loading,
  disabled,
  isCollapsed,
  handleCollapse,
  variant,
  lockGroups,
  filteredLockGroups,
  selectedIds,
  disabledIds,
  additionalTexts,
  warningTexts,
  handleClickTableRow,
  doors,
  selectedDoorsIds
}) => {
  const { sorting, handleSortingOnChange, sortingMenuRef } = useSortingAccessProfilesTableLockGroupsAndLocks({
    doors,
    lockGroups,
    selectedDoorsIds,
    selectedLocationsIds: selectedIds
  });

  const { showSortingMenu, hideSortingMenu, sortingMenuValue } = useSortingMenuAccessProfilesLockGroups();
  const skeletonArray = ArrayUtil.SkeletonArray();

  const isLockGroupSelected = (lockGroupId: string) => selectedIds.includes(lockGroupId);
  const isLockGroupDisabled = (lockGroupId: string) => disabledIds.includes(lockGroupId);
  const getLockGroupWithAdditionalText = (lockGroupId: string) =>
    additionalTexts.find((lockGroup) => lockGroup.id === lockGroupId)?.text;

  const getLockGroupWithWarningText = (lockGroupId: string) =>
    warningTexts.find((lockGroup) => lockGroup.id === lockGroupId)?.text;

  const getSecondLineText = (lockGroupId: string) =>
    getLockGroupWithAdditionalText(lockGroupId) || getLockGroupWithWarningText(lockGroupId);

  const getIconAndSecondLineTextColor = (lockGroupId: string) =>
    getLockGroupWithWarningText(lockGroupId) ? 'warning' : 'lTextHigh';

  const getFirstLineTextColor = (lockGroupId: string) =>
    variant === 'icon' && isLockGroupSelected(lockGroupId) && !isLockGroupDisabled(lockGroupId)
      ? 'primary'
      : 'lTextHigh';

  const getBeforeContentIconProps = (lockGroup: GetLocationsWithDoorsForTenant_getLocationsForTenant): IconProps => ({
    ...getLockGroupsIconProperties(
      isLockGroupSelected(lockGroup.id),
      lockGroup.doors?.length > 0,
      Boolean(getLockGroupWithWarningText(lockGroup.id))
    )
  });
  const [isVisible, setIsVisible] = useState<boolean | undefined>(undefined);

  const tableHeaderRef = useRef<null | HTMLDivElement>(null);

  const observer = new IntersectionObserver((entries) => {
    const entry = entries[0];
    setIsVisible(entry.isIntersecting);
  });

  if (tableHeaderRef.current) observer.observe(tableHeaderRef.current);

  useEffect(() => {
    if (sortingMenuValue && sortingMenuValue.isMenuOpen && !isVisible) hideSortingMenu();
  }, [hideSortingMenu, isVisible, sortingMenuValue]);

  return (
    <Table
      id={id}
      height={isCollapsed ? '3rem' : 'auto'}
      flex="0 1 auto"
      header={
        !loading ? (
          <div ref={tableHeaderRef}>
            <TableHeaderRow
              disabled={disabled}
              iconAfterHeaderProps={{
                name: 'Sorting',
                id: 'access-profiles-icon-sorting-lock-groups',
                onClick: !disabled
                  ? () =>
                      showSortingMenu({
                        isMenuOpen: true,
                        sorting,
                        sortingMenuRef,
                        sortingMenuIconId: 'access-profiles-icon-sorting-lock-groups',
                        handleSortingOnChange,
                        variant
                      })
                  : undefined
              }}
              iconAfterHeaderBorder
              id="access-profiles-header-row-locations"
              iconBeforeHeaderProps={{
                name: isCollapsed ? 'ArrowRight' : 'ArrowDown',
                width: isCollapsed ? ARROW_RIGHT_WIDTH : ARROW_DOWN_WIDTH,
                height: isCollapsed ? ARROW_RIGHT_HEIGHT : ARROW_DOWN_HEIGHT,
                color: isCollapsed ? undefined : 'transparent',
                stroke: isCollapsed ? undefined : 'lTextMedium',
                viewBox: isCollapsed ? '0 0 22 45' : undefined,
                onClick: handleCollapse
              }}
            >
              <TableHeader
                headerText="Lock Groups"
                superText={variant === 'switch' ? `(${selectedIds.length})` : undefined}
                id="access-profiles-header-locations"
              />
            </TableHeaderRow>
          </div>
        ) : (
          <TableHeaderRow
            disabled={disabled}
            placeForAvatarOrSwitchOrCheckbox={variant === 'icon'}
            id="access-profiles-header-row-lock-groups"
          >
            <TableHeader headerText="Lock Groups" id="access-profiles-header-user-groups" />
          </TableHeaderRow>
        )
      }
    >
      {loading
        ? skeletonArray.map((skeletonItem) => (
            <TableRow
              beforeSwitchSkeleton={variant === 'switch'}
              avatarSkeleton={variant === 'icon'}
              selected={false}
              disabled
              id="access-profiles-row-user-groups-loading"
              key={`skeletonTableRow-${skeletonItem.id}`}
            >
              <TableCell isLoading firstLineText="" />
            </TableRow>
          ))
        : !isCollapsed &&
          filteredLockGroups.map((lockGroup, index) => (
            <TableRow
              hoverEffect
              afterSwitchProps={
                variant !== 'switch'
                  ? undefined
                  : {
                      checked: isLockGroupSelected(lockGroup.id),
                      disabled: isLockGroupDisabled(lockGroup.id),
                      onClick: (event) => event.stopPropagation()
                    }
              }
              marginAfterContent={variant === 'switch'}
              beforeContentIconProps={getBeforeContentIconProps(lockGroup)}
              selected={variant === 'icon' ? isLockGroupSelected(lockGroup.id) : false}
              disabled={(variant === 'switch' && disabled) || isLockGroupDisabled(lockGroup.id)}
              onClick={
                !disabled && !isLockGroupDisabled(lockGroup.id) ? () => handleClickTableRow(lockGroup) : undefined
              }
              id={`access-profiles-row-locations-${index}`}
              key={lockGroup.id}
            >
              <TableCell
                firstLineText={lockGroup.name}
                firstLineColor={getFirstLineTextColor(lockGroup.id)}
                secondLineText={getSecondLineText(lockGroup.id)}
                secondLineColor={getIconAndSecondLineTextColor(lockGroup.id)}
                key={lockGroup.id}
                isRowWithIconAndSwitch
              />
            </TableRow>
          ))}
    </Table>
  );
};

export default AccessProfilesLockGroups;
