import React from 'react';
import styled from 'styled-components';
import { Colors } from 'theme/theme';
import Icon, { Icons } from 'ui/atoms/Icon/Icon';
import Separator from 'ui/atoms/Separator/Separator';
import Typography from 'ui/atoms/Typography/Typography';
import ComponentWrapper from 'ui/templates/ComponentWrapper/ComponentWrapper';

export interface Props {
  firstLineText: string;
  firstLineTextColor: keyof Colors;
  secondLineText: string;
  iconName: Icons;
  iconColor: keyof Colors;
  isHover: boolean;
  onClick?: () => void;
  id: string;
}

const Container = styled.div`
  width: 100%;
  min-width: 39rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 3rem;
`;

const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  &:hover div,
  &:hover svg {
    color: ${({ theme }) => theme.colors.primary};
    fill: ${({ theme }) => theme.colors.primary};
  }
`;

const MyProfileItem: React.FC<Props> = ({
  firstLineText,
  firstLineTextColor,
  secondLineText,
  iconName,
  iconColor,
  isHover,
  onClick,
  id
}) => (
  <>
    <Container id={id}>
      <ComponentWrapper flexDirection="column" gap="1rem">
        <Typography variant="body3" color={firstLineTextColor}>
          {firstLineText}
        </Typography>
        <Typography variant="body4" color="lTextHigh" opacity="0.6">
          {secondLineText}
        </Typography>
      </ComponentWrapper>
      {isHover ? (
        <DetailsWrapper onClick={onClick} id="my-profile-details-button">
          <Typography variant="clickable" color="lTextHigh">
            DETAILS
          </Typography>
          &nbsp;
          <Icon name="Edit" width={16} height={16} color="lTextHigh" />
        </DetailsWrapper>
      ) : (
        <div>
          <Icon name={iconName} color={iconColor} width={92} height={12} />
        </div>
      )}
    </Container>
    <Separator width="auto" />
  </>
);

export default MyProfileItem;
