import { AddExternalUserGroupForm } from 'components/Drawer/types';
import useExternalUsers from 'hooks/useExternalUsers/useExternalUsers';
import useDrawer from 'hooks/useDrawer/useDrawer';
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import DrawerTemplate from 'ui/organisms/Drawer/DrawerTemplate';
import AddExternalUserGroup from 'ui/organisms/Drawer/variants/AddExternalUserGroup';
import { formKeyDownHandler } from 'utils/InputOnKeyDown/formKeyDownHandler.util';

const AddExternalUserVariant: React.FC = () => {
  const [nameInputValue, setNameInputValue] = useState('');
  const [isNameUnique, setIsNameUnique] = useState(true);
  const [isMaximumUserLimitEnable, setIsMaximumUserLimitEnable] = useState<boolean>(false);
  const [maximumUserLimitValue, setMaximumUserLimitValue] = useState<number>();
  const { register, handleSubmit, setValue } = useForm<AddExternalUserGroupForm>();
  const { hideDrawer } = useDrawer();
  const enqueueSnackbar = useEnqueueSnackbar();
  const handleFetchError = (errorMessage: string) => enqueueSnackbar(errorMessage, { snackbartype: 'error' });
  const {
    addVisitorGroupWithMaxNumberOfMembers,
    addVisitorGroupWithMaxNumberOfMembersMutationLoading,
    externalUsersGroups
  } = useExternalUsers({
    handleFetchError
  });

  const handleNameInputOnChange = (value: string) => {
    setNameInputValue(value);
    if (externalUsersGroups.some((group) => group.name === value)) setIsNameUnique(false);
    else setIsNameUnique(true);
  };

  const handleIsMaximumUserLimitEnableOnChange = () => {
    setIsMaximumUserLimitEnable(!isMaximumUserLimitEnable);
  };

  const handleMaximumUserLimitValueOnChange = (value: number) => {
    setMaximumUserLimitValue(value);
  };

  const onSubmit = async () => {
    if (!addVisitorGroupWithMaxNumberOfMembersMutationLoading) {
      await addVisitorGroupWithMaxNumberOfMembers(nameInputValue, maximumUserLimitValue ?? null);
      hideDrawer();
    }
  };

  useEffect(() => {
    setValue('name', nameInputValue);
  }, [nameInputValue, setValue]);
  return (
    <DrawerTemplate
      title="Add group"
      confirmButtonText="Add"
      id="drawer-add-external-group"
      onSubmit={handleSubmit(onSubmit)}
      disableConfirmButton={!isNameUnique}
    >
      <AddExternalUserGroup
        register={register}
        nameInputValue={nameInputValue}
        handleNameInputOnChange={handleNameInputOnChange}
        isNameUnique={isNameUnique}
        switchIsMaximumUserLimitEnable={isMaximumUserLimitEnable}
        handleSwitchIsMaximumUserLimitEnableOnChange={handleIsMaximumUserLimitEnableOnChange}
        inputsMaximumUserLimitValue={maximumUserLimitValue}
        handlesMaximumUserLimitInputOnChange={handleMaximumUserLimitValueOnChange}
        inputOnKeyDown={(event) => formKeyDownHandler(event, onSubmit)}
      />
    </DrawerTemplate>
  );
};
export default AddExternalUserVariant;
