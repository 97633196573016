import { useReactiveVar } from '@apollo/client';
import dayjs from 'dayjs';
import useModal from 'hooks/useModal/useModal';
import React from 'react';
import { useForm } from 'react-hook-form';
import { authVar } from 'state/vars';
import DrawerTemplate from 'ui/organisms/Drawer/DrawerTemplate';
import QRCodeValueUtil from 'utils/QRCodeValue/QRCodeValue.util';
import MyProfileStatusConnected from 'ui/organisms/Drawer/variants/MyProfileStatusConnected';
import MyProfileStatusDisconnected from 'ui/organisms/Drawer/variants/MyProfileStatusDisconnected';
import useDrawer from 'hooks/useDrawer/useDrawer';

const AccessApp: React.FC = () => {
  const { handleSubmit } = useForm();
  const { user } = useReactiveVar(authVar);
  const { showModal } = useModal();
  const { hideDrawer } = useDrawer();

  const onSubmit = async () => {
    if (user?.mobileDevices?.find((device) => device.role === 'ACCESS')?.isRegistered)
      showModal({ type: 'disconnectMobileDevice' });
    else hideDrawer();
  };

  const isAccessMobileDeviceRegistered = user?.mobileDevices?.some(
    (device) => device.role === 'ACCESS' && device.isRegistered
  );

  const registrationToken = user?.mobileDevices?.find(
    (device) => device.role === 'ACCESS' && device.registrationToken
  )?.registrationToken;

  return (
    <DrawerTemplate
      title="ATLAS Access app"
      confirmButtonText={isAccessMobileDeviceRegistered ? 'Disconnect device' : 'Close'}
      id="drawer-access-app"
      onSubmit={handleSubmit(onSubmit)}
      closeButton={Boolean(isAccessMobileDeviceRegistered)}
      closeButtonText="Close"
    >
      {isAccessMobileDeviceRegistered ? (
        <MyProfileStatusConnected
          activeSinceDate={dayjs(user?.mobileDevices?.find((device) => device.role === 'ACCESS')?.registrationDate)}
        />
      ) : (
        <MyProfileStatusDisconnected
          qrCodeValue={registrationToken ? QRCodeValueUtil.getQrCodeValue(registrationToken) : null}
        />
      )}
    </DrawerTemplate>
  );
};
export default AccessApp;
