/* eslint-disable import/prefer-default-export */
/* eslint-disable no-magic-numbers */
import { OptionProps } from 'ui/molecules/Select/Select';

export const reasonSelectOptions: Array<OptionProps<null | string>> = [
  {
    value: 'No token',
    label: 'No token'
  },
  {
    value: 'Unknown',
    label: 'Unknown'
  }
];
