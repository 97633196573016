import React, { useCallback, useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import isBetweenPlugin from 'dayjs/plugin/isBetween';
import { styled } from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import { CalendarPicker } from '@mui/x-date-pickers/CalendarPicker';
import { colors } from 'theme/theme';
import { makeStyles } from '@material-ui/core';

dayjs.extend(isBetweenPlugin);

export interface Props {
  valueStart: null | dayjs.Dayjs;
  valueEnd: null | dayjs.Dayjs;
  onStartChange?: (value: null | dayjs.Dayjs) => void;
  onEndChange: (value: null | dayjs.Dayjs) => void;
  minDate?: Dayjs;
  maxDate?: Dayjs;
}
export interface CustomPickersDayProps extends PickersDayProps<Dayjs> {
  dayIsBetween: boolean;
  isFirstDay: boolean;
  isLastDay: boolean;
}

const useStyles = makeStyles({
  root: {
    '& .Mui-selected': {
      backgroundColor: `${colors.primary} !important`
    },
    '& .MuiPickersDay-root': {
      margin: 0
    }
  }
});

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== 'dayIsBetween' && prop !== 'isFirstDay' && prop !== 'isLastDay'
})<CustomPickersDayProps>(({ dayIsBetween, isFirstDay, isLastDay }) => ({
  ...(dayIsBetween && {
    borderRadius: 0,
    backgroundColor: colors.primary,
    color: colors.bgLight,
    '&:hover, &:focus': {
      backgroundColor: colors.bgDark
    }
  }),
  ...(isFirstDay && {
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%'
  }),
  ...(isLastDay && {
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%'
  })
})) as React.ComponentType<CustomPickersDayProps>;

const DatePicker: React.FC<Props> = ({
  valueStart,
  valueEnd,
  onStartChange,
  onEndChange,

  minDate = dayjs().subtract(1, 'month'),
  maxDate = dayjs()
}) => {
  const [value, setValue] = useState<Dayjs | null>(null);

  const onClick = useCallback(
    (newValue: dayjs.Dayjs) => {
      if (onStartChange === undefined) {
        onEndChange(newValue);
      }
      if (valueStart && valueStart.isSame(valueEnd, 'day')) {
        if (onStartChange !== undefined && valueStart.isAfter(newValue)) {
          onEndChange(valueStart);
          onStartChange(newValue);
        } else onEndChange(newValue);
      }
      if (onStartChange !== undefined && valueEnd && valueStart && !valueStart.isSame(valueEnd, 'day')) {
        onStartChange(newValue);
        onEndChange(newValue);
      }
      if (onStartChange !== undefined && valueStart === null) {
        onStartChange(newValue);
        onEndChange(newValue);
      }
      setValue(newValue);
    },
    [onEndChange, onStartChange, valueEnd, valueStart]
  );

  useEffect(() => {
    if (onStartChange === undefined && valueEnd) {
      onClick(valueEnd);
    }
  }, [onClick, onStartChange, valueEnd]);

  const classes = useStyles();

  const renderWeekPickerDay = (
    date: Dayjs,
    selectedDates: Array<Dayjs | null>,
    pickersDayProps: PickersDayProps<Dayjs>
  ) => {
    if (!value) {
      return <PickersDay {...pickersDayProps} />;
    }

    const dayIsBetween = date.isBetween(valueStart, valueEnd, null, '[]');
    const isFirstDay = date.isSame(valueStart, 'day');
    const isLastDay = date.isSame(valueEnd, 'day');

    return (
      <CustomPickersDay
        {...pickersDayProps}
        disableMargin
        dayIsBetween={dayIsBetween}
        isFirstDay={isFirstDay}
        isLastDay={isLastDay}
        className={classes.root}
      />
    );
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <CalendarPicker
        className={classes.root}
        // eslint-disable-next-line no-magic-numbers
        minDate={minDate}
        maxDate={maxDate}
        date={value}
        openTo="day"
        onChange={(newValue) => {
          onClick(newValue!);
        }}
        renderDay={renderWeekPickerDay}
      />
    </LocalizationProvider>
  );
};

export default DatePicker;
