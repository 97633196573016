import React from 'react';
import styled from 'styled-components';
import MenuItem, { Props as MenuItemProps } from 'ui/atoms/MenuItem/MenuItem';
import Profile, { Props as ProfileProps } from 'ui/molecules/Profile/Profile';
import Typography from 'ui/atoms/Typography/Typography';
import ComponentWrapper from 'ui/templates/ComponentWrapper/ComponentWrapper';
import Title from 'components/Title/Title';
import { useNavigate } from 'react-router-dom';
import Separator from 'ui/atoms/Separator/Separator';

const StyledLine = styled.div`
  height: 0;
  border-top: 1px solid ${(props) => props.theme.colors.secondary};
`;

const StyledNav = styled.nav`
  box-shadow: inset 0 0.25rem 0.625rem rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.colors.bgDark};
`;

const StyledContainer = styled.div`
  padding: 2rem 1.5rem 1.125rem 1.5rem;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const ProfileWrapper = styled.div`
  flex: 0 0 4rem;
  display: flex;
  padding: 0 1.5rem 0 1.5rem;
`;

const LogoutWrapper = styled.div`
  background: ${(props) => props.theme.colors.bgDark};
  box-shadow: inset 0 -0.25rem 0.625rem rgba(0, 0, 0, 0.25);
  flex: 0 0 4rem;
  display: flex;
  padding: 1.4375rem 1.5rem;
  width: 100%;
  cursor: pointer;
`;

export interface Props {
  items: MenuItemProps[];
  profile: ProfileProps;
  isAdmin: boolean;
  singOut: () => void;
  profileOnClick?: () => void;
}

const SideNav: React.FC<Props> = ({ items, profile, isAdmin, singOut, profileOnClick }) => {
  const navigate = useNavigate();
  return (
    <StyledNav>
      <StyledContainer id="sidebar">
        <Title onClick={() => navigate('/')} />
        <ComponentWrapper flexDirection="column" justifyContent="space-between" margin="auto 0">
          {items.map(({ icon, text, location, isSelected }, index) =>
            isAdmin && index === 1 ? (
              <div key={`SideNavItem-${location}`}>
                <Separator height="0.5rem" />
                <MenuItem key={location} icon={icon} text={text} location={location} isSelected={isSelected} />
                <Separator height="0.5rem" />
              </div>
            ) : (
              <MenuItem key={location} icon={icon} text={text} location={location} isSelected={isSelected} />
            )
          )}
        </ComponentWrapper>
      </StyledContainer>
      <StyledLine />
      <ProfileWrapper onClick={profileOnClick}>
        <Profile name={profile.name} picture={profile.picture} hoverEffect={!!profileOnClick} />
      </ProfileWrapper>
      <StyledLine />
      <LogoutWrapper onClick={singOut} id="navbar-logout">
        <Typography variant="clickable" color="lTextHigh">
          Log out
        </Typography>
      </LogoutWrapper>
    </StyledNav>
  );
};

export default SideNav;
