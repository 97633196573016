import { makeVar } from '@apollo/client';
import {
  authInitialState,
  doorsInitialState,
  locationsInitialState,
  requestStateInitialState,
  uiInitialState,
  visitorsGroupsInitialState,
  installersGroupsInitialState,
  accessEventFiltersState,
  schedulesInitialState,
  externalUsersGroupsInitialState,
  officeDaysInitialState,
  officeAvailableDaysInitialState,
  visitorsGroupsMembersInitialState,
  permissionsInitialState,
  visitorGroupsWithIsOfficeModeEnabledInitialState,
  locationsWithDoorsInitialState,
  doorsWithLocationsInitialState,
  visitorsGroupsWithAccessGrantsState
} from './initialStates';

export const doorsVar = makeVar(doorsInitialState);
export const doorsWithLocationsVar = makeVar(doorsWithLocationsInitialState);
export const authVar = makeVar(authInitialState);
export const locationsVar = makeVar(locationsInitialState);
export const locationsWithDoorsVar = makeVar(locationsWithDoorsInitialState);
export const locationsAndDoorsRefetchVar = makeVar(false);
export const uiVar = makeVar(uiInitialState);
export const accessEventFiltersVar = makeVar(accessEventFiltersState);
export const visitorsGroupsVar = makeVar(visitorsGroupsInitialState);
export const visitorsGroupsMembersVar = makeVar(visitorsGroupsMembersInitialState);
export const visitorsGroupsWithAccessGrantsVar = makeVar(visitorsGroupsWithAccessGrantsState);
export const externalUsersGroupsVar = makeVar(externalUsersGroupsInitialState);
export const installersGroupsVar = makeVar(installersGroupsInitialState);
export const schedulesVar = makeVar(schedulesInitialState);
export const requestStateVar = makeVar(requestStateInitialState);
export const officeDaysVar = makeVar(officeDaysInitialState);
export const officeAvailableDaysVar = makeVar(officeAvailableDaysInitialState);
export const permissionsVar = makeVar(permissionsInitialState);
export const visitorGroupsWithIsOfficeModeEnabledVar = makeVar(visitorGroupsWithIsOfficeModeEnabledInitialState);
