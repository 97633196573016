/* eslint-disable import/prefer-default-export */
/* eslint-disable no-magic-numbers */
import dayjs from 'dayjs';
import { OptionProps } from 'ui/molecules/Select/Select';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export interface DateTimeSelectItem {
  value: number;
  unit: dayjs.ManipulateType;
}

export const dataTimeSelectOptions: Array<OptionProps<null | DateTimeSelectItem>> = [
  {
    value: null,
    label: 'Custom range'
  },
  {
    value: { value: 15, unit: 'minutes' },
    label: 'Last 15 minutes'
  },
  {
    value: { value: 1, unit: 'hour' },
    label: 'Last 1 hour'
  },
  {
    value: { value: 6, unit: 'hour' },
    label: 'Last 6 hour'
  },
  {
    value: { value: 12, unit: 'hour' },
    label: 'Last 12 hour'
  },
  {
    value: { value: 24, unit: 'hour' },
    label: 'Last 24 hour'
  },
  {
    value: { value: 48, unit: 'hour' },
    label: 'Last 48 hour'
  },
  {
    value: { value: 72, unit: 'hour' },
    label: 'Last 72 hour'
  }
];
