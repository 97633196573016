import { isEqual, orderBy } from 'lodash';
import { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { externalUsersGroupsVar } from 'state/vars';
import { headerGroups } from 'pages/Users/ExternalUsers/ExternalUsers';
import { setExternalUsersGroupsAction } from 'state/actions/externalUsersGroups';
import {
  GetVisitorGroupsForTenantWithMembersAndRole_getVisitorGroupsForTenant,
  GetVisitorGroupsForTenantWithMembersAndRole_getVisitorGroupsForTenant_members
} from 'graphql/generated/GetVisitorGroupsForTenantWithMembersAndRole';

interface Sorting {
  isAscending?: boolean;
  columnName?: string;
}

const getMemberRole = (
  item: GetVisitorGroupsForTenantWithMembersAndRole_getVisitorGroupsForTenant_members,
  externalUsersGroups: GetVisitorGroupsForTenantWithMembersAndRole_getVisitorGroupsForTenant[]
) => {
  const role = item.visitorGroupMembership.find((groupMembership) =>
    externalUsersGroups.some((group) => groupMembership.visitorGroup.externalRef === group.externalRef)
  )?.role;
  switch (role) {
    case 'EXTERNAL_GROUP_USER':
      return 'User';
    case 'EXTERNAL_GROUP_USER_MANAGER':
      return 'Owner';

    default:
      return undefined;
  }
};

export const handleSortingUsers = (
  item: GetVisitorGroupsForTenantWithMembersAndRole_getVisitorGroupsForTenant_members,
  externalUsersGroups: GetVisitorGroupsForTenantWithMembersAndRole_getVisitorGroupsForTenant[],
  columnName?: string
) => {
  switch (columnName) {
    case 'ATLAS Access app':
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
      return !item.mobileDevices.find(({ role, isRegistered }) => role === 'ACCESS' && isRegistered)?.isRegistered;
    case 'Expiration date':
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
      return item.expirationDate;
    case 'Role':
      return getMemberRole(item, externalUsersGroups);
    case 'External users':
    default:
      return item.name;
  }
};

export const handleSortingGroups = (
  item: GetVisitorGroupsForTenantWithMembersAndRole_getVisitorGroupsForTenant,
  columnName?: typeof headerGroups[number]
) => {
  switch (columnName) {
    case 'External groups':
    default:
      return item.name;
  }
};

export const getSortedArrayGroups = (
  externalUsersGroups: GetVisitorGroupsForTenantWithMembersAndRole_getVisitorGroupsForTenant[],
  sorting: Sorting
) =>
  orderBy(externalUsersGroups, (item) => handleSortingGroups(item, sorting.columnName as typeof headerGroups[number]), [
    sorting.isAscending ? 'asc' : 'desc'
  ]);

export const getSortedArrayUsers = (
  externalUsersMembers: GetVisitorGroupsForTenantWithMembersAndRole_getVisitorGroupsForTenant_members[],
  externalUsersGroups: GetVisitorGroupsForTenantWithMembersAndRole_getVisitorGroupsForTenant[],
  sorting: Sorting
) =>
  orderBy(externalUsersMembers, (item) => handleSortingUsers(item, externalUsersGroups, sorting.columnName), [
    sorting.isAscending ? 'asc' : 'desc'
  ]);

const useSortingExternalUsersTable = () => {
  const externalUsersGroups = useReactiveVar(
    externalUsersGroupsVar
  ) as GetVisitorGroupsForTenantWithMembersAndRole_getVisitorGroupsForTenant[];
  const [sortingGroups, setSortingGroups] = useState<Sorting>({ isAscending: true, columnName: 'External groups' });
  const [sortingUsers, setSortingUsers] = useState<Sorting>({
    isAscending: true,
    columnName: 'External users'
  });

  const handleSortingGroupsOnClick = (columnName: typeof headerGroups[number]) => {
    if (sortingGroups.isAscending !== undefined && sortingGroups.columnName === columnName) {
      return setSortingGroups({ isAscending: !sortingGroups.isAscending, columnName });
    }
    return setSortingGroups({ isAscending: true, columnName });
  };

  const handleSortingUsersOnClick = (columnName: string) => {
    if (sortingUsers.isAscending !== undefined && sortingUsers.columnName === columnName) {
      return setSortingUsers({ isAscending: !sortingUsers.isAscending, columnName });
    }
    return setSortingUsers({ isAscending: true, columnName });
  };

  const handleSortingGroupsIcon = (columnName: typeof headerGroups[number]) => {
    if (sortingGroups.isAscending !== undefined)
      if (sortingGroups.columnName === columnName) return sortingGroups.isAscending ? 'ArrowUp' : 'ArrowDown';
    return undefined;
  };

  const handleSortingUsersIcon = (columnName: string) => {
    if (sortingUsers.isAscending !== undefined)
      if (sortingUsers.columnName === columnName) return sortingUsers.isAscending ? 'ArrowUp' : 'ArrowDown';
    return undefined;
  };

  useEffect(() => {
    const sortedExternalUsersGroups = getSortedArrayGroups(externalUsersGroups, sortingGroups);
    const sortedExternalUsersGroupsWithSortedMembers = sortedExternalUsersGroups.map((group) => ({
      ...group,
      members: getSortedArrayUsers(group.members, externalUsersGroups, sortingUsers)
    }));
    if (!isEqual(sortedExternalUsersGroupsWithSortedMembers, externalUsersGroups))
      setExternalUsersGroupsAction(sortedExternalUsersGroupsWithSortedMembers);
  }, [externalUsersGroups, sortingGroups, sortingUsers]);

  return { handleSortingGroupsOnClick, handleSortingUsersOnClick, handleSortingGroupsIcon, handleSortingUsersIcon };
};

export default useSortingExternalUsersTable;
