import React from 'react';
import Icon from 'ui/atoms/Icon/Icon';
import Typography from 'ui/atoms/Typography/Typography';
import ComponentWrapper from 'ui/templates/ComponentWrapper/ComponentWrapper';

const NoGroups: React.FC = () => (
  <ComponentWrapper
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    gap="1.5rem"
    width="20.125rem"
    height="11rem"
  >
    <Icon name="UserCoContractors" width={56} height={56} viewBox="0 0 24 24" />
    <Typography variant="title4">No Groups</Typography>
    <Typography variant="body4">There are currently no groups</Typography>
  </ComponentWrapper>
);

export default NoGroups;
