import React from 'react';
import Typography from 'ui/atoms/Typography/Typography';
import ComponentWrapper from 'ui/templates/ComponentWrapper/ComponentWrapper';

const OfficeModeTooltipInfo: React.FC = () => (
  <ComponentWrapper flexDirection="column" width="14rem" padding="0.5rem 1rem" gap="1rem">
    <Typography variant="body4" color="lTextMedium">
      Enabling this option will allow a user with the correct access permissions, to put the lock into Office Mode by
      presenting their mobile device
    </Typography>
    <Typography variant="body4" color="lTextMedium">
      The lock will automatically lock itself outside the set time schedule.
    </Typography>
  </ComponentWrapper>
);

export default OfficeModeTooltipInfo;
