import { AddExternalUserForm } from 'components/Drawer/types';
import {
  EmailInputValidation,
  ExpirationDateInputValidation,
  NameInputValidation
} from 'components/Drawer/variants/validationTexts';
import React from 'react';
import { Control, Controller, UseFormRegister } from 'react-hook-form';
import Switch from 'ui/atoms/Switch/Switch';
import Typography from 'ui/atoms/Typography/Typography';
import Input from 'ui/molecules/Input/Input';
import ComponentWrapper from 'ui/templates/ComponentWrapper/ComponentWrapper';
import Select, { OptionProps } from 'ui/molecules/Select/Select';
import { Role } from 'graphql/generated/globalTypes';
import Icon from 'ui/atoms/Icon/Icon';
import styled from 'styled-components';
import Tooltip from 'ui/atoms/Tooltip/Tooltip';

const IconWrapper = styled.div`
  cursor: pointer;
  align-items: center;
  margin-top: 1.5rem;
`;
export interface Props {
  register: UseFormRegister<AddExternalUserForm>;
  nameInputValue: string;
  handleNameInputOnChange: (value: string) => void;
  nameInputValidation: NameInputValidation;
  emailInputValue: string;
  handleEmailInputValueChange: (value: string) => void;
  emailInputValidation: EmailInputValidation;
  switchIsExpirationDateEnabled: boolean | undefined;
  handleSwitchIsExpirationDateOnChange: () => void;
  inputExpirationDateToValue: string | undefined;
  handleExpirationDateInputOnClick: () => void;
  inputExpirationDateToValueValidation: ExpirationDateInputValidation;
  showValidation: boolean;
  inputOnKeyDown: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  control: Control<AddExternalUserForm, object>;
  selectValue: null | OptionProps<Role | null>;
  inputValue: string;
  selectOption: OptionProps<Role | null>[];
  handleSelectInputChange: (value: string) => void;
  handleSelectChange: (value: OptionProps<Role> | null) => void;
}
const AddExternalUser: React.FC<Props> = ({
  register,
  nameInputValue,
  handleNameInputOnChange,
  nameInputValidation,
  emailInputValue,
  handleEmailInputValueChange,
  emailInputValidation,
  switchIsExpirationDateEnabled,
  handleSwitchIsExpirationDateOnChange,
  inputExpirationDateToValue,
  handleExpirationDateInputOnClick,
  inputExpirationDateToValueValidation,
  showValidation,
  inputOnKeyDown,
  control,
  selectValue,
  inputValue,
  selectOption,
  handleSelectChange,
  handleSelectInputChange
}) => (
  <>
    <Input
      {...register('name')}
      onChange={(event) => handleNameInputOnChange(event.target.value)}
      customRequired
      label="Name*"
      failed={showValidation && nameInputValidation !== undefined}
      value={nameInputValue}
      failedText={nameInputValidation}
      failedId="drawer-input-name-validation"
      id="drawer-input-name"
      onKeyDown={inputOnKeyDown}
    />
    <Input
      {...register('email')}
      customRequired
      onChange={(event) => handleEmailInputValueChange(event.target.value)}
      label="E-Mail*"
      failed={showValidation && emailInputValidation !== undefined}
      value={emailInputValue}
      failedText={emailInputValidation}
      failedId="drawer-input-email-validation"
      id="drawer-input-email"
      onKeyDown={inputOnKeyDown}
    />
    <ComponentWrapper>
      <Controller
        control={control}
        name="role"
        render={() => (
          <Select
            fieldTemplateProps={{
              label: 'Role'
            }}
            autocompleteProps={{
              options: selectOption,
              value: selectValue,
              inputValue,
              onInputChange: (_, value) => {
                if (value !== 'undefined') handleSelectInputChange(value);
              },
              onChange: (_, value) => {
                handleSelectChange(value as OptionProps<Role>);
              },
              // @ts-ignore
              getOptionLabel: (option) => option.label as string,
              id: 'drawer-select-role-id',
              onKeyDown: inputOnKeyDown,
              sx: { width: 343 },
              disableClearable: true
            }}
            textFieldProps={{ placeholder: 'No role selected' }}
            {...register('role')}
          />
        )}
      />
      <Tooltip
        elementOnHover={
          <ComponentWrapper flexDirection="column" width="20rem" padding="1rem">
            <Typography variant="body4" color="lTextHigh">
              A user with the “Owner” role has the ability
            </Typography>
            <Typography variant="body4" color="lTextHigh">
              to invite other users to this group up to
            </Typography>
            <Typography variant="body4" color="lTextHigh">
              maximum user count.
            </Typography>
          </ComponentWrapper>
        }
        id="role-tooltip"
      >
        <IconWrapper>
          <Icon name="Info" id="locks-i-icon-role" />
        </IconWrapper>
      </Tooltip>
    </ComponentWrapper>
    <Input
      {...register('expirationDate')}
      customRequired
      label="Expiration date"
      failed={showValidation && inputExpirationDateToValueValidation !== undefined}
      value={inputExpirationDateToValue}
      failedText={inputExpirationDateToValueValidation}
      disabled={!switchIsExpirationDateEnabled}
      failedId="drawer-input-expiration-date-validation"
      id="drawer-input-expiration-date"
      onKeyDown={inputOnKeyDown}
      onClick={switchIsExpirationDateEnabled ? handleExpirationDateInputOnClick : undefined}
    />
    <ComponentWrapper flexDirection="row" gap="1rem" justifyContent="space-between" alignItems="center" width="100%">
      <Typography variant="body1" color="dTextHigh">
        Never expire
      </Typography>
      <Switch
        variant="dark"
        {...register('isOfficeModeEnabled')}
        id="drawer-enable-expiration-date-span"
        inputId="drawer-enable-expiration-date-input"
        checked={!switchIsExpirationDateEnabled}
        onClick={handleSwitchIsExpirationDateOnChange}
        onKeyDown={inputOnKeyDown}
      />
    </ComponentWrapper>
  </>
);

export default AddExternalUser;
