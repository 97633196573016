import { isEqual, orderBy } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { schedulesVar } from 'state/vars';
import { SchedulesState } from 'state/types';
import { headers } from 'pages/Access/SchedulesPage/SchedulesPage';
import { GetVisitorGroupsForTenantWithSchedules_getVisitorGroupsForTenant } from 'graphql/generated/GetVisitorGroupsForTenantWithSchedules';
import { setSchedulesAction } from 'state/actions/schedules';

interface Sorting {
  isAscending?: boolean;
  columnName?: typeof headers[number];
}

export const handleSorting = (
  item: GetVisitorGroupsForTenantWithSchedules_getVisitorGroupsForTenant,
  columnName?: typeof headers[number]
) => {
  switch (columnName) {
    case 'Name':
    default:
      return item.name;
  }
};

export const getSortedArray = (schedules: SchedulesState, sorting: Sorting) =>
  orderBy(schedules, (item) => handleSorting(item, sorting.columnName), [sorting.isAscending ? 'asc' : 'desc']);

const useSortingSchedulesTable = () => {
  const schedules = useReactiveVar(schedulesVar);
  const [sorting, setSorting] = useState<Sorting>({ isAscending: true, columnName: 'Name' });

  const handleSortingOnClick = useCallback(
    (columnName: typeof headers[number]) => {
      if (sorting.isAscending !== undefined && sorting.columnName === columnName) {
        return setSorting({ isAscending: !sorting.isAscending, columnName });
      }
      return setSorting({ isAscending: true, columnName });
    },
    [sorting.columnName, sorting.isAscending]
  );

  const handleSortingIcon = (columnName: string) => {
    if (sorting.isAscending !== undefined)
      if (sorting.columnName === columnName) return sorting.isAscending ? 'ArrowUp' : 'ArrowDown';
    return undefined;
  };

  useEffect(() => {
    const sortedSchedules = getSortedArray(schedules, sorting);
    if (!isEqual(sortedSchedules, schedules)) setSchedulesAction(sortedSchedules);
  }, [schedules, sorting]);

  return { handleSortingOnClick, handleSortingIcon };
};

export default useSortingSchedulesTable;
