/* eslint-disable no-magic-numbers */
import React, { useRef } from 'react';
import styled from 'styled-components';
import Icon from 'ui/atoms/Icon/Icon';
import ComponentWrapper from 'ui/templates/ComponentWrapper/ComponentWrapper';

const StyledInput = styled.input<Pick<Props, 'variant' | 'value'>>`
  padding-left: 0.5rem;
  background-color: transparent;
  border: 0;
  width: 100%;
  color: ${({ theme, value }) =>
    value !== undefined && value !== '' ? theme.colors.lTextHigh : theme.colors.lTextMedium};
  font-size: 1rem;

  &:focus {
    outline: none;
  }
`;

const StyledContainer = styled.div<Pick<Props, 'variant'>>`
  height: ${({ variant }) => (variant === 'small' ? '2rem' : '3.375rem')};
  width: 100%;
  display: flex;
  align-items: center;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.secondary};
  border: ${({ variant, theme }) => (variant === 'small' ? undefined : `1px solid ${theme.colors.secondary}`)};

  &:focus-within {
    border: ${({ variant, theme }) => (variant === 'small' ? undefined : `1px solid ${theme.colors.primary}`)};
    svg {
      fill: ${({ theme }) => theme.colors.lTextHigh};
    }
  }

  &:hover {
    border: ${({ variant, theme }) => (variant === 'small' ? undefined : `1px solid ${theme.colors.lTextHigh}`)};
  }

  &:hover ${StyledInput}::placeholder {
    color: ${({ theme }) => theme.colors.lTextHigh};
  }
`;

const StyledLine = styled.div<Pick<Props, 'value'>>`
  border-left: 1px solid
    ${({ value, theme }) => (value !== undefined && value !== '' ? theme.colors.lTextHigh : theme.colors.secondary)};
  height: 1.5rem;
`;

export type Variant = 'small' | 'big';

export interface Props extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  clearButtonOnClick?: () => void;
  variant?: Variant;
  id?: string;
}

const Search = React.forwardRef<HTMLInputElement | null, Props>((props) => {
  const { value, clearButtonOnClick, variant = 'small', id = 'search' } = props;
  const inputRef = useRef<HTMLInputElement>(null);

  const handleClickWrapper = () => {
    if (inputRef.current) inputRef.current.focus();
  };

  const isSmall = Boolean(variant === 'small');
  return (
    <StyledContainer variant={variant} onClick={handleClickWrapper}>
      <ComponentWrapper
        justifyContent="center"
        alignItems="center"
        width="1.75rem"
        padding={isSmall ? undefined : '0 0 0 0.5rem'}
      >
        <Icon
          name="Search"
          width={isSmall ? 11 : 24}
          height={isSmall ? 10 : 24}
          viewBox={isSmall ? undefined : '0 0 12 12'}
          color={value !== undefined && value !== '' ? 'primary' : 'lTextMedium'}
          id={`${id}-icon`}
        />
      </ComponentWrapper>
      {isSmall && <StyledLine value={value} id={`${id}-line`} />}
      <StyledInput placeholder="Type to Search..." {...props} ref={inputRef} id={`${id}-input`} />
      {clearButtonOnClick !== undefined && value !== undefined && value !== '' && (
        <ComponentWrapper
          cursor="pointer"
          onClick={clearButtonOnClick}
          margin={variant === 'big' ? '0 0.5rem 0 0' : undefined}
        >
          <Icon name="Close" width={12} height={12} viewBox="0 0 14 14" color="lTextMedium" id={`${id}-icon`} />
        </ComponentWrapper>
      )}
    </StyledContainer>
  );
});

export default Search;
