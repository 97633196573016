import React from 'react';
import styled from 'styled-components';
import Loader from 'ui/atoms/Loader/Loader';
import Typography from 'ui/atoms/Typography/Typography';
import ComponentWrapper from 'ui/templates/ComponentWrapper/ComponentWrapper';

interface GradientPosition {
  bottom: number;
  right: number;
  transformOriginX: number;
  transformOriginY: number;
}

const BackgroundGradient = styled.div<GradientPosition>`
  @keyframes move {
    100% {
      transform: rotate(105deg);
    }
  }

  width: 20%;
  height: 20%;
  position: fixed;
  bottom: ${({ bottom }) => bottom}%;
  right: ${({ right }) => right}%;
  animation: move;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-duration: 10s;
  transform-origin: ${({ transformOriginX }) => transformOriginX}% ${({ transformOriginY }) => transformOriginY}%;
  background: linear-gradient(180deg, #515511 0%, #15150c 100%);
  filter: blur(200px);
`;

const WelcomeTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const Welcome: React.FC = () => (
  <ComponentWrapper width="100vw" height="100vh" flexDirection="column" justifyContent="center" alignItems="center">
    <WelcomeTextWrapper>
      <Typography variant="title1">Welcome&nbsp;to&nbsp;</Typography>
      <Typography variant="title1" color="primary">
        Atlas
      </Typography>
    </WelcomeTextWrapper>

    <BackgroundGradient bottom={30} right={0} transformOriginX={-100} transformOriginY={-100} />
    <BackgroundGradient bottom={80} right={80} transformOriginX={100} transformOriginY={100} />

    <Loader />
  </ComponentWrapper>
);

export default Welcome;
