import React from 'react';
import Modal from 'ui/atoms/Modal/Modal';
import Button from 'ui/atoms/Button/Button';
import Typography from 'ui/atoms/Typography/Typography';
import Icon from 'ui/atoms/Icon/Icon';
import useModal from 'hooks/useModal/useModal';
import ComponentWrapper from 'ui/templates/ComponentWrapper/ComponentWrapper';
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar';
import { ResendInvitationModalForm } from 'state/types';
import useExternalUsers from 'hooks/useExternalUsers/useExternalUsers';

const ResendInvitation: React.FC = () => {
  const { hideModal, modalState } = useModal();
  const enqueueSnackbar = useEnqueueSnackbar();
  const modalStateInitialValue: ResendInvitationModalForm = modalState?.contentValue;
  const handleFetchError = (errorMessage: string) => enqueueSnackbar(errorMessage, { snackbartype: 'error' });
  const { sendInvitationForMobileDeviceById, sendInvitationForMobileDeviceByIdMutationLoading } = useExternalUsers({
    handleFetchError
  });
  const mobileDevice = modalStateInitialValue?.mobileDevices.find(
    (device) => device.role === modalStateInitialValue?.type
  );
  const handleResendInvitation = () => {
    if (mobileDevice && !sendInvitationForMobileDeviceByIdMutationLoading) {
      sendInvitationForMobileDeviceById(modalStateInitialValue.visitorId, mobileDevice.id)
        .then((data) => {
          if (data?.sendInvitationForMobileDeviceById) {
            enqueueSnackbar(
              `${
                modalStateInitialValue?.type.charAt(0) + modalStateInitialValue?.type.slice(1).toLowerCase()
              } invitation successfully send`,
              {
                snackbartype: 'success'
              }
            );
          }
        })
        .finally(hideModal);
    }
  };

  return (
    <Modal>
      <ComponentWrapper
        flexDirection="column"
        width="36rem"
        gap="2rem"
        margin="1rem 0"
        justifyContent="center"
        alignItems="center"
      >
        <Icon name="SendEmail" color="dTextHigh" width={40} height={40} viewBox="0 0 24 24" id="delete-bin-icon" />
        <ComponentWrapper flexDirection="column" justifyContent="center" alignItems="center">
          <ComponentWrapper flexDirection="column" justifyContent="center" alignItems="center">
            <Typography variant="title2" color="dTextHigh">
              Are you sure that you want
            </Typography>
            <Typography variant="title2" color="dTextHigh">
              to resend the {modalStateInitialValue?.type.toLocaleLowerCase()} invitation e-mail?
            </Typography>
          </ComponentWrapper>
        </ComponentWrapper>
        <ComponentWrapper width="100%">
          <Button backgroundColor="transparent" textColor="b1" onClick={hideModal} id="cancel-button-modal">
            No, Thank you
          </Button>
          <Button onClick={handleResendInvitation} id="ok-button-modal">
            Yes, i&apos;m sure
          </Button>
        </ComponentWrapper>
      </ComponentWrapper>
    </Modal>
  );
};

export default ResendInvitation;
