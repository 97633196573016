import React, { forwardRef } from 'react';
import styled from 'styled-components';
import Separator from 'ui/atoms/Separator/Separator';
import SortingMenuHeader from 'ui/molecules/SortingMenuHeader/SortingMenuHeader';
import SortingMenuItem, { Props as SortingMenuItemProps } from 'ui/molecules/SortingMenuItem/SortingMenuItem';
import ComponentWrapper from 'ui/templates/ComponentWrapper/ComponentWrapper';

const StyledSortingMenu = styled.div`
  position: absolute;
  display: flex;
  top: 100%;
  right: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.b6};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  width: 15rem;
  z-index: 1;
`;

const StyledContainer = styled.div<Partial<Props>>`
  position: relative;
  display: inline-block;
  width: 100%;

  ${({ isOpen }) =>
    isOpen &&
    ` & ${StyledSortingMenu} {
      visibility: visible;
    }`};
`;
export interface Props {
  isOpen: boolean;
  sortingName: string;
  sortingOptions: SortingMenuItemProps[];
  additionalSortingName?: string;
  additionalSortingOptions?: Array<SortingMenuItemProps | undefined>;
  id?: string;
  onClick?: () => void;
}

const SortingMenu = forwardRef<HTMLDivElement | null, React.PropsWithChildren<Props>>((props, ref) => {
  const {
    isOpen,
    children,
    sortingName,
    sortingOptions,
    additionalSortingName,
    additionalSortingOptions,
    id,
    onClick
  } = props;

  if (isOpen)
    return (
      <StyledContainer isOpen={isOpen} ref={ref} onClick={onClick}>
        <StyledSortingMenu>
          <ComponentWrapper
            flexDirection="column"
            columnGap="1rem"
            backgroundColor="b5"
            padding="1rem"
            id={id}
            width="15rem"
          >
            <SortingMenuHeader text={sortingName} />
            {sortingOptions.map((option) => (
              <SortingMenuItem
                isSelected={option.isSelected}
                text={option.text}
                onClick={option.onClick}
                id={option.id}
                key={`sortingMenuItem-${option.id}`}
              />
            ))}
            {additionalSortingOptions && additionalSortingName && (
              <>
                <ComponentWrapper margin="0.25rem 0">
                  <Separator width="13rem" color="bgBlur" />
                </ComponentWrapper>
                <SortingMenuHeader text={additionalSortingName} />

                {additionalSortingOptions.map(
                  (option) =>
                    option && (
                      <SortingMenuItem
                        isSelected={option.isSelected}
                        text={option.text}
                        onClick={option.onClick}
                        id={option.id}
                        key={`sortingMenuAdditionalItem-${option.id}`}
                      />
                    )
                )}
              </>
            )}
          </ComponentWrapper>
        </StyledSortingMenu>
        {children}
      </StyledContainer>
    );
  return <>{children}</>;
});
export default SortingMenu;
