import React from 'react';
import { Colors } from 'theme/theme';
import { CalendarDayBorderRadius, CalendarDayVariant } from 'ui/atoms/CalendarDay/CalendarDay';
import CalendarUI from 'ui/molecules/Calendar/Calendar';
import ArrayUtil from 'utils/Array/Array.util';
import dayjs, { Dayjs } from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import { isMobile } from 'react-device-detect';

dayjs.extend(weekday);

export interface Props {
  disableDates?: Dayjs[];
  selectedDates?: Dayjs[];
  dateOfRender: Dayjs;
  isMobileMenuOpen: boolean;
  onSelectDate?: (day: number) => void;
  showNextMonth: () => void;
  showPreviousMonth: () => void;
}

const LENGTH_OF_WEEK = 7;

export const nameOfDays = (() =>
  Array.from(new Array(LENGTH_OF_WEEK)).map((_, index) =>
    dayjs()
      .weekday(index + 1)
      .format('dd')
  ))();

export const getArrayOfDays = (date: Dayjs) => {
  const NumberOfDaysToShift =
    Math.abs(LENGTH_OF_WEEK - (+date.format('D') % LENGTH_OF_WEEK) + +date.format('d')) % LENGTH_OF_WEEK;
  if (NumberOfDaysToShift !== 0)
    return [
      ...Array.from(new Array(NumberOfDaysToShift)),
      ...ArrayUtil.Range(dayjs(date).daysInMonth(), 1)
    ] as number[];
  return [...ArrayUtil.Range(dayjs(date).daysInMonth(), 1)];
};

const Calendar: React.FC<Props> = ({
  disableDates = [],
  selectedDates = [],
  onSelectDate,
  dateOfRender = dayjs(),
  showNextMonth,
  showPreviousMonth
}) => {
  const todayDate = dayjs();

  const handleTextColor = (day: number): keyof Colors => {
    const dateOfColor = dateOfRender.date(day);
    if (disableDates.some((date) => date.isSame(dateOfColor, 'date'))) {
      return 'lTextMedium';
    }
    if (todayDate.isAfter(dateOfColor, 'date')) {
      return selectedDates.some((date) => date.get('date') === day) ? 'dTextHigh' : 'lTextMedium';
    }
    if (todayDate.isSame(dateOfColor, 'date')) {
      return selectedDates.some((date) => date.get('date') === day) ? 'lTextHigh' : 'primary';
    }
    return 'lTextHigh';
  };

  const handleDayVariant = (day: number): CalendarDayVariant => {
    if (day === undefined) return 'Empty';
    const dateOfVariant = dateOfRender.date(day);
    if (todayDate.isAfter(dateOfVariant, 'date')) {
      if (disableDates.filter((date) => date.isSame(dateOfVariant, 'date')).length > 0) return 'Disabled';
      return selectedDates.filter((date) => date.isSame(dateOfVariant, 'date')).length > 0 ? 'PastSelected' : 'Past';
    }
    if (selectedDates.filter((date) => date.isSame(dateOfVariant, 'date')).length > 0) return 'Selected';
    if (disableDates.filter((date) => date.isSame(dateOfVariant, 'date')).length > 0) return 'Disabled';
    return 'Default';
  };

  const handleLastDayOfWeekBorderRadius = (day: number) => {
    if (day === 1) return 'TopRounding';
    if (day <= LENGTH_OF_WEEK) return 'RightTopRounding';
    if (day > todayDate.daysInMonth() - LENGTH_OF_WEEK) return 'RightBottomRounding';
    return 'Default';
  };

  const handleFirstDayOfWeekBorderRadius = (day: number) => {
    if (todayDate.daysInMonth() === todayDate.date(day).date()) return 'BottomRounding';
    if (day <= LENGTH_OF_WEEK) return 'LeftTopRounding';
    if (day > todayDate.daysInMonth() - LENGTH_OF_WEEK) return 'LeftBottomRounding';
    return 'Default';
  };

  const handleMiddleDaysOfWeekBorderRadius = (day: number) => {
    if (day === 1) return 'LeftTopRounding';
    if (todayDate.daysInMonth() === todayDate.date(day).date()) return 'RightBottomRounding';
    return 'Default';
  };

  const handleDayBorderRadius = (day: number): CalendarDayBorderRadius => {
    if (day !== undefined) {
      switch (todayDate.date(day).day()) {
        case 0:
          return handleLastDayOfWeekBorderRadius(day);
        case 1:
          return handleFirstDayOfWeekBorderRadius(day);
        default:
          return handleMiddleDaysOfWeekBorderRadius(day);
      }
    }
    return 'Default';
  };

  const handleFontSize = (day: number) => {
    const variant = handleDayVariant(day);

    if (['PastSelected', 'Selected'].includes(variant)) return 'title2';

    return 'title3';
  };

  return (
    <CalendarUI
      monthNameAndYear={dateOfRender.format('MMMM YYYY')}
      nameOfDays={nameOfDays}
      arrayOfDays={getArrayOfDays(dateOfRender)}
      isNextMonthShown={todayDate.isSame(dateOfRender, 'month')}
      showNextMonth={showNextMonth}
      showPreviousMonth={showPreviousMonth}
      handleTextColor={handleTextColor}
      handleDayVariant={handleDayVariant}
      handleDayBorderRadius={handleDayBorderRadius}
      handleFontSize={handleFontSize}
      onSelectDate={onSelectDate}
      isMobile={isMobile}
    />
  );
};

export default React.memo(Calendar, (prevProps, nextProps) => {
  if (prevProps.isMobileMenuOpen !== nextProps.isMobileMenuOpen) return true; // not re-render calendar when user click at hamburger

  return false;
});
