import { EditInstallerForm } from 'components/Drawer/types';
import { NameInputValidation } from 'components/Drawer/variants/validationTexts';
import React from 'react';
import { UseFormRegister } from 'react-hook-form';
import { MobileType } from 'state/types';
import Button from 'ui/atoms/Button/Button';
import Icon from 'ui/atoms/Icon/Icon';
import Typography from 'ui/atoms/Typography/Typography';
import Input from 'ui/molecules/Input/Input';
import ComponentWrapper from 'ui/templates/ComponentWrapper/ComponentWrapper';

export interface Props {
  register: UseFormRegister<EditInstallerForm>;
  nameInputValue: string;
  handleNameInputOnChange: (value: string) => void;
  nameInputValidation: NameInputValidation;
  emailInputValue: string;
  statusAccess: boolean;
  statusConfig: boolean;
  showValidation: boolean;
  isEditable?: boolean;
  isResendInvitationButtonVisibleConfig: boolean;
  isResendInvitationButtonVisibleAccess: boolean;
  handleOnResendInvitationClick: (type: MobileType) => void;
  inputOnKeyDown: (event: React.KeyboardEvent<HTMLDivElement>) => void;
}

const EditInstaller: React.FC<Props> = ({
  register,
  nameInputValue,
  emailInputValue,
  handleNameInputOnChange,
  nameInputValidation,
  statusAccess,
  statusConfig,
  showValidation,
  isEditable,
  isResendInvitationButtonVisibleConfig,
  isResendInvitationButtonVisibleAccess,
  handleOnResendInvitationClick,
  inputOnKeyDown
}) => (
  <>
    <ComponentWrapper flexDirection="column" width="100%" alignItems="flex-start" justifyContent="center" gap="1rem">
      <Typography color="dTextHigh" variant="label">
        ATLAS Access app
      </Typography>
      <Icon
        name={statusAccess ? 'StatusConnected' : 'StatusDisconnected'}
        color={statusAccess ? 'success' : 'error'}
        // eslint-disable-next-line no-magic-numbers
        width={statusAccess ? 75 : 92}
        height={12}
      />
      <Typography color="dTextHigh" variant="label">
        ATLAS Config app
      </Typography>
      <Icon
        name={statusConfig ? 'StatusConnected' : 'StatusDisconnected'}
        color={statusConfig ? 'success' : 'error'}
        // eslint-disable-next-line no-magic-numbers
        width={statusConfig ? 75 : 92}
        height={12}
      />
      <Input
        {...register('name')}
        onChange={isEditable ? (event) => handleNameInputOnChange(event.target.value) : undefined}
        customRequired
        label="Name*"
        value={nameInputValue}
        id="drawer-input-name"
        failed={showValidation && nameInputValidation !== undefined}
        failedText={nameInputValidation}
        failedId="drawer-input-name-validation"
        disabled={!isEditable}
        onKeyDown={inputOnKeyDown}
      />
      <Input
        {...register('email')}
        required
        label="E-mail*"
        value={emailInputValue}
        id="drawer-input-email"
        disabled
        onKeyDown={inputOnKeyDown}
      />
      {isResendInvitationButtonVisibleAccess && (
        <Button onClick={() => handleOnResendInvitationClick('ACCESS')} id="resend-access-invitation-button">
          Resend access invitation
        </Button>
      )}
      {isResendInvitationButtonVisibleConfig && (
        <Button onClick={() => handleOnResendInvitationClick('CONFIG')} id="resend-config-invitation-button">
          Resend config invitation
        </Button>
      )}
    </ComponentWrapper>
  </>
);

export default EditInstaller;
